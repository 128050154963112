<template>
  <router-link class="d-flex align-center" to="/">
    <v-img
      class="shrink"
      width="86"
      title="IRMA"
      alt="IRMA"
      transition="scale-transition"
      contain
      src="@/assets/logo.svg"
    />
  </router-link>
</template>

<script>
export default {
  name: 'TheLogo',
}
</script>
