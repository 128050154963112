export default [
  {
    name: 'AngularDimension',
    code: '96788994-b7fc-41d7-8a99-d674543e9237',
    parentCategory: '',
  },
  {
    name: 'AssemblyInstance',
    code: '00799249-1824-4ebd-bf93-40bb92efa9e6',
    parentCategory: '',
  },
  {
    name: 'Axis',
    code: '4b41ccf8-c969-4c55-a1f2-cced9c164f07',
    parentCategory: '',
  },
  {
    name: 'Beam',
    code: '63478188-7c88-4a6d-b891-9725f04a5bc7',
    parentCategory: '',
  },
  {
    name: 'Column',
    code: 'd9ee2442-e807-42fb-8fe5-9dcfe543035d',
    parentCategory: '',
  },
  {
    name: 'DiametralDimension',
    code: '2aabe3a4-a29e-4534-a9f5-0f070fee240c',
    parentCategory: '',
  },
  {
    name: 'Door',
    code: '1cfba99c-01e7-4078-ae1a-3e2ff0673599',
    parentCategory: '',
  },
  {
    name: 'Duct',
    code: '06cc88ee-9a67-4626-9c34-dde03c331a74',
    parentCategory: '',
  },
  {
    name: 'DuctAccessory',
    code: '47d0d93f-3c7b-4269-bf8a-de246e1724d0',
    parentCategory: '',
  },
  {
    name: 'DuctFitting',
    code: '77ffca60-b20e-49f0-b42f-4fdc9b1c825b',
    parentCategory: '',
  },
  {
    name: 'ElectricDistributionBoard',
    code: '96da9155-43c1-42b8-bba2-b4f61fa43acc',
    parentCategory: '',
  },
  {
    name: 'Element',
    code: 'e1e3bd66-2e13-4fa4-a9eb-677e03067c2f',
    parentCategory: '',
  },
  {
    name: 'Elevation',
    code: '8a49a9a8-a401-4ab1-8038-92093503c97a',
    parentCategory: '',
  },
  {
    name: 'Equipment',
    code: '5d2f3734-5a49-4504-90b1-0676f0f25da7',
    parentCategory: '',
  },
  {
    name: 'Floor',
    code: 'f5bd8bd8-39c1-47f8-8499-f673c580dfbe',
    parentCategory: '',
  },
  {
    name: 'Hatch',
    code: '84b43087-d4a4-4cce-b34d-40e283d9e691',
    parentCategory: '',
  },
  {
    name: 'IfcObject',
    code: 'f914251d-d5fa-48b2-b93b-074f442cbf3b',
    parentCategory: '',
  },
  {
    name: 'IsolatedFoundation',
    code: '6063816c-89ff-4c8f-a814-3be6cb94128e',
    parentCategory: '',
  },
  {
    name: 'Level',
    code: 'c3ce17ff-6f28-411f-b18d-74fe957b2ba8',
    parentCategory: '',
  },
  {
    name: 'LightFixture',
    code: '793d3f7c-905d-4d85-a351-b152241dd2e7',
    parentCategory: '',
  },
  {
    name: 'Line3D',
    code: '02bbebe8-e28b-4ee5-8916-11b514a35dca',
    parentCategory: '',
  },
  {
    name: 'LineElectricalCircuit',
    code: '83de45e6-4793-49ec-8b9e-65a2438f36de',
    parentCategory: '',
  },
  {
    name: 'LinearDimension',
    code: 'dc82ca1a-a0c3-4a1a-aefb-a7d720dd3a09',
    parentCategory: '',
  },
  {
    name: 'MechanicalEquipment',
    code: 'de4420ce-02b6-4b12-9cd7-9322118be8fe',
    parentCategory: '',
  },
  {
    name: 'Opening',
    code: 'fc443d5a-b76c-45e5-b91c-520ef0896109',
    parentCategory: '',
  },
  {
    name: 'Pipe',
    code: '838cc9f6-e3d8-4132-af6f-c58df0f8d037',
    parentCategory: '',
  },
  {
    name: 'PipeAccessory',
    code: '41e2788a-49ed-487f-9ae1-55b6e09ae6e5',
    parentCategory: '',
  },
  {
    name: 'PipeFitting',
    code: 'd31dc2e3-808e-4987-8481-7f86665a07fc',
    parentCategory: '',
  },
  {
    name: 'Plate',
    code: '62cf086e-5a39-4484-840c-ffa6a1c6e2b7',
    parentCategory: '',
  },
  {
    name: 'PlumbingFixture',
    code: 'b8c7155a-b462-4ff5-bc41-c9c17a9f48fa',
    parentCategory: '',
  },
  {
    name: 'RadialDimension',
    code: '377c2fda-9411-43ac-a6c6-0e3b520be721',
    parentCategory: '',
  },
  {
    name: 'Railing',
    code: 'a1aca786-78a4-4015-b412-9150baad71a9',
    parentCategory: '',
  },
  {
    name: 'Ramp',
    code: 'debde004-afcc-4da8-8dd0-4223ff836acd',
    parentCategory: '',
  },
  {
    name: 'Rebar',
    code: '9fabc932-590f-4068-89a8-ee6ee3d7cbbf',
    parentCategory: '',
  },
  {
    name: 'Roof',
    code: 'bac4470f-d560-4f57-a49e-faa5f6e5a279',
    parentCategory: '',
  },
  {
    name: 'Room',
    code: 'f1a805ff-573d-f46b-ffba-57f4bccaa6ed',
    parentCategory: '',
  },
  {
    name: 'Route',
    code: '8b323bee-3882-4744-8838-24f45df714a9',
    parentCategory: '',
  },
  {
    name: 'RoutePoint',
    code: 'ce93e320-7167-4cd1-92a8-5e42d546066b',
    parentCategory: '',
  },
  {
    name: 'Section',
    code: '4166fd59-64c0-45ee-ae3b-49fae1257ef1',
    parentCategory: '',
  },
  {
    name: 'Stair',
    code: '3f522f49-aee2-4d73-9866-9b07cf336a69',
    parentCategory: '',
  },
  {
    name: 'TextObject',
    code: 'da557027-f243-4331-bb5b-853abc437cd7',
    parentCategory: '',
  },
  {
    name: 'Undefined',
    code: '97675473-ca62-4ea4-bc6e-bb2ca57b7e67',
    parentCategory: '',
  },
  {
    name: 'Wall',
    code: '4329112a-6b65-48d9-9da8-abf1f8f36327',
    parentCategory: '',
  },
  {
    name: 'WallFoundation',
    code: 'd7dd0293-dd65-4229-a64c-8b528d4e226f',
    parentCategory: '',
  },
  {
    name: 'Window',
    code: '2b02b353-2ca5-4566-88bb-917ea8460174',
    parentCategory: '',
  },
  {
    name: 'WiringAccessory',
    code: 'b00d5c25-92a8-4409-a3b7-7c37ed792c06',
    parentCategory: '',
  },
]
