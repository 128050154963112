import { pick, merge, cloneDeep, camelCase } from 'lodash'

import { keysToCamelCaseDeep, renameKeysBackDeep } from '@/utils/renameKeys'

export default class Model {
  static model = {}

  static getModel(
    modelValues = {},
    model = this.model,
    toCamelCaseDeep = false
  ) {
    let keys = Object.keys(model)
    let merged = merge(cloneDeep(model), modelValues)

    if (toCamelCaseDeep) {
      keys = keys.map(camelCase)
      merged = keysToCamelCaseDeep(merged)
    }

    return pick(merged, keys)
  }

  static prepareOriginCaseModel(model) {
    return renameKeysBackDeep(model, this.model)
  }
}
