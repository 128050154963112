export default [
  {
    path: '*',
    name: '404',
    component: () => import('../views/NotFound404/NotFound404.vue'),
  },
  {
    path: '/',
    name: 'Проекты',
    component: () => import('../views/Projects/Projects.vue'),
  },
  {
    path: '/projects',
    redirect: '/',
  },
  {
    path: '/projects/:projectId',
    name: 'Проект',
    component: () => import('../views/ProjectItem/ProjectItem.vue'),
  },
  {
    path: '/projects/:projectId/settings',
    name: 'Настройки проекта',
    component: () => import('../views/ProjectItem/Settings/Settings.vue'),
  },
  {
    path: '/projects/:projectId/eirs/:eirId',
    name: 'Дашборд: требование',
    component: () => import('../views/ProjectEir/ProjectEir.vue'),
  },
  {
    path: '/projects/:projectId/pz',
    name: 'Пояснительная записка',
    component: () => import('../views/ProjectItem/PZ/PZ.vue'),
  },
  {
    path: '/eir-sets',
    name: 'Информационные требования',
    component: () => import('../views/EirSets/EirSets.vue'),
  },
  {
    path: '/eir-sets/:setId',
    name: 'Набор требований',
    component: () => import('../views/EirSetItem/EirSetItem.vue'),
  },
  {
    path: '/eir-sets/:setId/eirs/:eirId',
    name: 'Требование',
    component: () => import('../views/Eir/Eir.vue'),
  },
  {
    path: '/eir-sets/:setId/versions/:versionId',
    name: 'Версия',
    component: () => import('../views/EirSetItem/Versions/Versions.vue'),
  },
  {
    path: '/catalogs',
    name: 'Каталоги',
    component: () => import('../views/Сatalogs/Сatalogs.vue'),
  },
  {
    path: '/catalogs/catalog/:id',
    name: 'Catalog',
    component: () => import('../views/Сatalogs/Сatalog.vue'),
    props: true,
  },
]
