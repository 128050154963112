<template>
  <v-btn rounded class="ml-1 mt-1 px-1" text small @click="$emit('show-modal')">
    <v-icon class="mr-1">mdi-help-circle-outline</v-icon>
    <span>Помощь</span>
  </v-btn>
</template>

<script>
export default {
  name: 'TheHelpButton',
}
</script>
