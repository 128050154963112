/*
 * Примеры запросов и ответов:
 * https://git.bimlib.ru/irma/irma-plugin-http-server/-/wikis/%D0%9E%D0%B1%D0%BC%D0%B5%D0%BD-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8
 * */

import FilterCondition from '@/models/FilterCondition'
import ParameterSapr from '@/models/ParameterSapr'
import { DEFAULT_NOTIFY_SNACKBAR } from '@/store/constants'
import cloneDeep from 'lodash/cloneDeep'
import checkParameter from '@/utils/checkParameter'

const endpoint = '/api/model'

export default {
  /**
   * @description Синхронизация с Ревитом
   * */
  async syncWithRevit({ commit }) {
    commit('switchSyncStatus', 2)
    try {
      const { data } = await this.$revit.get(`${endpoint}/status`)

      commit('updateRevitSyncStatus', data.status)
      commit('updateOpenedDocuments', data.openedDocuments)
      commit('updateActiveDocument', data.activeDocument)
      commit('updatePluginVersion', data.pluginVersion)
      commit('updateRevitVersion', data.appVersion)

      if (data.status === 'Failed') {
        if (data.pluginVersion && !data.openedDocuments?.length) {
          const options = {
            ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
            message:
              'САПР с плагином обнаружен, но не открыто ни одного файла!',
            show: true,
          }
          options.options.color = 'error'
          commit('ui/triggerNotifySnackbar', options, { root: true })
        }

        throw Error('В процессе подключения к Ревиту произошла ошибка!')
      }

      commit('switchSyncStatus', 3)
    } catch (e) {
      commit('switchSyncStatus', 0)
      const message = e?.message === 'Network Error' ? 'Нет связи' : e?.message
      this.$logger('warn', '[Revit/sync]', message)
    }
  },
  /**
   * @description Тестовый запрос для проверки фильтра
   * @param eirElementId {string}
   * @param filter {conditions, parameters}
   * @param conditions {array}
   * @param parameters {array}
   * */
  async filterCheck(
    { commit, state },
    { eirElementId, filter: { conditions, parameters } }
  ) {
    commit('switchCheckStatus', { eirElementId, status: 2, isTest: true })

    try {
      const {
        data: { elements: items, status },
      } = await this.$revit.post(`${endpoint}/check`, {
        activeFileName: state.activeDocument?.filePath ?? '',
        eirElement: {
          uuid: eirElementId,
          filter: {
            conditions: conditions.map((row) =>
              FilterCondition.prepareCamelCaseModel(row)
            ),
          },
          parameters: prepareParams(parameters, 'check'),
        },
      })

      const [elements] = prepareElements(items)
      commit('updateRevitElements', {
        eirElementId,
        elements,
        isTest: true,
      })

      commit('updateRevitSyncStatus', status)
      commit('switchCheckStatus', { eirElementId, status: 3, isTest: true })
    } catch (e) {
      commit('switchCheckStatus', { eirElementId, status: 0, isTest: true })
      this.$logger('error', '[Revit/filterCheck]', e)
    }
  },
  /**
   * @description Фильтрация и последующее сравнение с параметрами конкретного требования
   * @param projectId {number} Id проекта, из которого ведутся проверки
   * @param eirElementId {string}
   * @param filter {conditions, parameters}
   * @param conditions {array}
   * @param parameters {array}
   * @param stopSwitch {boolean} для ручного управления лоадером
   * */
  async checkRevitProject(
    { commit, state },
    {
      projectId,
      eirElementId,
      filter: { conditions, parameters },
      stopSwitch = false,
    }
  ) {
    if (
      state.currentCheckProject !== null &&
      state.currentCheckProject !== projectId
    ) {
      if (
        !confirm(
          'Все результаты проверок из другого проекта будут утеряны, вы уверены?'
        )
      )
        return

      commit('resetStateOfChecks')
    }
    commit('updateCurrentCheckProject', projectId)

    if (!stopSwitch) commit('switchCheckStatus', { eirElementId, status: 2 })

    try {
      const {
        data: { elements: items, parameters: revitParameters, status },
      } = await this.$revit.post(`${endpoint}/check`, {
        activeFileName: state.activeDocument?.filePath ?? '',
        eirElement: {
          uuid: eirElementId,
          filter: {
            conditions: conditions.map((row) =>
              FilterCondition.prepareCamelCaseModel(row)
            ),
          },
          parameters: [
            // { name: 'Описание' },
            // { name: 'URL' },
            ...prepareParams(parameters, 'check'),
          ],
        },
      })

      const [elements, countMatch, countPresent, countParameters] =
        prepareElements(items)
      commit('updateRevitElements', {
        eirElementId,
        elements,
      })
      commit('updateRevitElementsCount', {
        eirElementId,
        countMatch,
        countPresent,
      })

      const progress = Math.floor((countMatch / elements.length) * 100)
      commit('updateRevitCheckProgress', {
        eirElementId,
        progress: isNaN(progress) ? 0 : progress,
      })
      this.$logActionEvent('Проведена проверка', {
        progress: isNaN(progress) ? 0 : progress,
      })
      /*
       * Считаем сколько всего на элементах и сколько должно быть по каждому параметру
       * */
      Object.keys(countParameters).forEach((paramName) => {
        commit('updateRevitParametersCount', {
          paramName,
          countTotal: countParameters[paramName].total,
          countPresent: countParameters[paramName].present,
        })
      })

      commit('updateRevitParameters', revitParameters)
      commit('updateRevitSyncStatus', status)

      if (!stopSwitch) commit('switchCheckStatus', { eirElementId, status: 3 })
    } catch (e) {
      commit('switchCheckStatus', { eirElementId, status: 0 })
      this.$logger('error', '[Revit/checkRevitProject]', e)
    }
  },
  /**
   * @description Изменить активный документ
   * */
  async changeActiveDocument({ commit, state }, activeDocument) {
    const oldActiveDocument = cloneDeep(state.activeDocument)
    commit('updateActiveDocument', activeDocument)

    commit('switchChangeDocumentStatus', 2)
    try {
      const { data } = await this.$revit.post(
        `${endpoint}/changeActiveDocument`,
        {
          document: activeDocument.filePath,
        }
      )

      commit('updateRevitSyncStatus', data.status)

      if (data.status === 'Failed') throw Error('Не удалось сменить документ!')

      commit('updateOpenedDocuments', data.openedDocuments)
      commit('switchChangeDocumentStatus', 3)
    } catch (e) {
      commit('updateActiveDocument', oldActiveDocument)
      commit('switchChangeDocumentStatus', 0)
      this.$logger('error', '[Revit/changeActiveDocument]', e)
    }
  },
  /**
   * @description Выделить элементы в Ревите
   * */
  async selectElements(
    { commit, state },
    {
      elements = state.selectedSaprElements?.map(({ id }) => id) ?? [],
      eirElementId,
      notMatch = false,
    }
  ) {
    if (!elements?.length) {
      const revitElements = state.revitElements[eirElementId] ?? []
      const filtered = notMatch
        ? revitElements.filter((item) => !item.match)
        : revitElements

      elements = filtered?.map(({ id }) => id)
    }

    commit('switchSelectElementsStatus', 2)
    try {
      const { data } = await this.$revit.post(`${endpoint}/select`, {
        activeFileName: state.activeDocument?.filePath ?? '',
        elements,
      })

      commit('updateRevitSyncStatus', data.status)

      if (data.status === 'Failed') throw Error('Не удалось выделить элементы!')

      commit('switchSelectElementsStatus', 3)
    } catch (e) {
      commit('switchSelectElementsStatus', 0)
      this.$logger('error', '[Revit/selectElements]', e)
    }
  },
  /**
   * @description Применение параметров экземпляра ко списку элементов
   * */
  async applyParamsRevit(
    { commit, state, getters },
    { parameters, eirElementId, elementsIds }
  ) {
    if (!elementsIds?.length)
      elementsIds = getters
        .getSelectedSaprElements(eirElementId)
        .map(({ id }) => id)

    let options = {
      ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
      message: 'Все параметры успешно записаны!',
      show: true,
    }
    let failedElementsIds = []

    commit('switchApplyStatus', 2)

    try {
      const { data } = await this.$revit.post(`${endpoint}/saveToElements`, {
        activeFileName: state.activeDocument?.filePath ?? '',
        parameters: prepareParams(parameters, 'apply'),
        elements: elementsIds,
      })

      failedElementsIds = data.failedElementsIds ?? []

      const errors = {
        Failed: `Ошибка в элементах: ${
          failedElementsIds?.join(', ') ?? ''
        }`,
        Busy: 'САПР занят!',
      }

      commit('updateRevitSyncStatus', data.status)

      if (data.status !== 'Success') throw Error(errors[data.success])

      commit('switchApplyStatus', 3)
    } catch (e) {
      commit('setFailedElementsIds', failedElementsIds)
      commit('switchApplyStatus', 0)
      this.$logger('error', '[Revit/applyParamsRevit]', e)

      options.message = failedElementsIds?.length
        ? 'Есть элементы, на которые записать не удалось'
        : 'Записать не удалось'
      options.options.color = 'error'
    } finally {
      commit('ui/triggerNotifySnackbar', options, { root: true })
    }
  },
  /**
   * @description Создание параметра
   * */
  async createParamRevit(
    { state, commit, getters },
    { parameter, eirElementId, elementsIds }
  ) {
    if (!elementsIds?.length)
      elementsIds = getters
        .getSelectedSaprElements(eirElementId)
        .map(({ id }) => id)

    let options = {
      ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
      message: 'Параметр успешно создан!',
      show: true,
    }

    commit('switchCreateStatus', {
      eirElementId,
      paramName: parameter.name,
      status: 2,
    })

    try {
      const { data } = await this.$revit.post(`${endpoint}/CreateParameter`, {
        activeFileName: state.activeDocument?.filePath ?? '',
        parameterDefinition: {
          saprFields: prepareSaprFields(parameter.saprFields),
          ...parameter,
        },
        elements: elementsIds,
      })

      const errors = {
        Failed: 'Ошибка создания параметра!',
        Busy: 'САПР занят!',
      }

      commit('updateRevitSyncStatus', data.status)

      if (data.status !== 'Success') throw Error(errors[data.success])

      commit('switchCreateStatus', {
        eirElementId,
        paramName: parameter.name,
        status: 3,
      })
    } catch (e) {
      commit('switchCreateStatus', {
        eirElementId,
        paramName: parameter.name,
        status: 0,
      })
      this.$logger('error', '[Revit/createParamRevit]', e)

      options.message = 'Создать параметр не удалось'
      options.options.color = 'error'
    } finally {
      commit('ui/triggerNotifySnackbar', options, { root: true })
    }
  },
  /**
   * @description Получение словарей
   * */
  async getDictionaries({ commit }) {
    try {
      const { data: paramsData } = await this.$revit.get(
        `api/dictionary/GetAllBuiltInParameters`
      )
      if (paramsData.status !== 'Success')
        throw Error('Получить builtInParameters не удалось!')

      const { data: categoriesData } = await this.$revit.get(
        `api/dictionary/GetAllBuiltInCategories`
      )
      if (categoriesData.status !== 'Success')
        throw Error('Получить builtInCategories не удалось!')

      const params = prepareDictionary(paramsData.parameters)
      const categories = prepareDictionary(categoriesData.categories)

      commit('updateDictionaries', {
        params,
        categories,
      })
    } catch (e) {
      const message = e?.message === 'Network Error' ? 'Нет связи' : e?.message

      this.$logger('warn', '[Revit/getDictionaries]', message)
    }
  },
}

function prepareParams(params, type) {
  return params.map((param) => {
    const options =
      type === 'check'
        ? {
            isRequired: param.is_required ?? false,
            checkRegexp: param.check_regexp ?? '',
          }
        : {
            value: param.value,
            overwriteExistingValue: param.overwriteExistingValue,
          }

    return {
      name: param.name,
      saprFields: prepareSaprFields(
        param.saprFields ?? param.sapr_parameters ?? []
      ),
      ...options,
    }
  })
}

function prepareSaprFields(fields) {
  const reducedFields = Array.isArray(fields)
    ? fields.reduce((acc, field) => {
        acc[field.name] = field.value

        return acc
      }, {})
    : fields

  return ParameterSapr.getModel(reducedFields, undefined, true)
}

function prepareElements(revitElements) {
  let countParameters = {}
  let countMatch = 0
  let countPresent = 0

  const elements = revitElements.map((revitElement) => {
    const [match, present, presentParametersByKey] =
      checkElementParams(revitElement)

    revitElement.match = match
    revitElement.present = present

    countMatch = countMatch + match

    Object.keys(presentParametersByKey).forEach((key) => {
      if (!countParameters[key])
        countParameters[key] = {
          total: 0,
          present: 0,
        }

      countParameters[key].total++

      countParameters[key].present =
        countParameters[key].present + presentParametersByKey[key]
    })

    return revitElement
  })

  Object.keys(countParameters).forEach(
    (key) =>
      (countPresent =
        countPresent +
        Math.floor(countParameters[key].present / revitElements.length))
  )

  return [elements, countMatch, countPresent, countParameters]
}

function checkElementParams(revitElement) {
  if (!revitElement?.parameters?.length) return [null, null, {}]

  let presentParametersByKey = {}
  let present = true

  const match = revitElement.parameters.reduce((checked, param) => {
    presentParametersByKey[param.name] = param.present
    present = present && param.present

    return checked && checkParameter(param)
  }, true)

  return [match, present, presentParametersByKey]
}

function prepareDictionary(dictionary = []) {
  return dictionary
    ?.map((param) => ({
      ...param,
      fullName: `${param.name} (${param.code})`,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1))
}
