import dateFormat, { i18n } from 'dateformat'

i18n.dayNames = [
  'Вс',
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Сб',
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
]

i18n.monthNames = [
  'Янв',
  'Фев',
  'Март',
  'Апр',
  'Май',
  'Ию',
  'Ил',
  'Авг',
  'Сент',
  'Окт',
  'Ноя',
  'Дек',
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
]

const formatDate = (value, mask = 'dd.mm.yyyy') => {
  if (typeof value === 'string') {
    const parts = value.split(' ')

    if (parts.length <= 1) {
      return value
    }

    const date = parts[0].split('-')
    const time = parts[1].split(':')
    value = new Date(date[2], parseInt(date[1], 10) - 1, date[0], ...time)
  }
  return dateFormat(value, mask)
}

export default formatDate
