import cloneDeep from 'lodash/cloneDeep'

export const vTableOptionsPrepare = (options) => {
  options = cloneDeep(options)

  const page = options?.page
  const itemsPerPage = options?.itemsPerPage
  const sortDesc = options?.sortDesc?.[0] ?? '-' ? '-' : ''
  const sortBy = options?.sortBy?.[0] ?? 'created_at'

  const params = {
    offset: calcOffset(page, itemsPerPage),
    ordering: sortDesc + sortBy,
    limit: 99,
  }

  if (itemsPerPage >= 0) params.limit = itemsPerPage
  else if (itemsPerPage === -1) params.limit = 0

  return params
}

function calcOffset(page, itemsPerPage) {
  if (page === 1) return 0

  return (itemsPerPage < 0 ? 0 : itemsPerPage) * (page - 1) || 0
}
