export const SAPR_FIELDS_LABELS = {
  REVIT_GUID: 'GUID',
  REVIT_TYPE: 'Тип параметра',
  REVIT_DATA_TYPE: 'Тип данных',
  REVIT_PARAMETER_GROUP: 'Группа параметров в Revit',
  REVIT_IS_SHARED: 'Общий',
  REVIT_IS_SYSTEM: 'Код параметра',
  REVIT_IS_READONLY: 'Только для чтения',
  // Renga
  rengaGUID: 'GUID',
  rengaParameterType: 'Тип данных',
  rengaParameterValueType: 'Тип параметра',
}
