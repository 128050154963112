<template>
  <v-menu
    v-model="popover"
    open-on-hover
    transition="slide-y-transition"
    nudge-bottom="25"
    bottom
    max-width="330"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-btn
            rounded
            class="mr-2 mt-1 px-1"
            small
            v-bind="attrs"
            :class="[
              `elevation-${hover ? 24 : 10}`,
              { 'green-elevation': connected, 'red-elevation': !connected },
            ]"
            :color="connected ? 'primary' : 'error'"
            bordered
            title="Проверить статус"
            v-on="on"
            @click="connect"
          >
            <v-icon class="mr-2">mdi-alpha-r-circle-outline</v-icon>
            <span class="mr-2">
              {{ btnText }}
            </span>
            <v-progress-circular
              v-if="connecting"
              :width="3"
              :size="20"
              indeterminate
              class="ml-1"
            />
            <v-icon v-else>mdi-autorenew</v-icon>
          </v-btn>
        </template>
      </v-hover>
    </template>

    <v-card>
      <v-card-text class="pb-1">
        <div>
          {{ text }}
        </div>
        <div v-if="pluginVersion">
          <span class="font-weight-bold">Ваша версия плагина:&nbsp;</span>
          {{ pluginVersion }}
        </div>
        <div v-if="revitVersion?.versionName">
          <span class="font-weight-bold">Текущий САПР:&nbsp;</span>
          {{ revitVersion?.versionName }}
        </div>
        <v-btn
          label
          color="primary"
          small
          target="_blank"
          :href="pluginLinkRevit"
          elevation="10"
          class="mt-2"
        >
          <v-icon class="mr-2" size="20">mdi-cloud-download</v-icon>
          Скачать плагин для Revit
        </v-btn>
        <v-btn
          label
          color="primary"
          small
          target="_blank"
          :href="pluginLinkRenga"
          elevation="10"
          class="mt-2"
        >
          <v-icon class="mr-2" size="20">mdi-cloud-download</v-icon>
          Скачать плагин для Renga
        </v-btn>
        <div class="mt-2">
          <v-btn
            text
            small
            color="primary"
            class="px-0"
            target="_blank"
            :href="instructionLink"
          >
            <v-icon class="mr-1" size="20">mdi-information-outline</v-icon>
            Инструкция по установке Revit
          </v-btn>
        </div>
        <div class="mt-1">
          <v-btn
            text
            small
            color="primary"
            class="px-0"
            target="_blank"
            :href="demoLink"
            @click.native="$logActionEvent('Скачан демо-проект для Ревита')"
          >
            <v-icon class="mr-1" size="20">mdi-alpha-r-box-outline</v-icon>
            Демо-проект для Ревита
          </v-btn>
          <div class="caption">(.rvt, от 2020 и выше)</div>
        </div>
      </v-card-text>
      <v-card-subtitle class="caption pt-0">
        Поддерживаются версии Ревита с&nbsp;2019&nbsp;по&nbsp;2023
      </v-card-subtitle>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import some from 'lodash/some'

export default {
  name: 'TheSaprStatus',
  data: () => ({
    popover: false,
    pluginLinkRevit: process.env.VUE_APP_REVIT_INSTALLER_LINK || null,
    pluginLinkRenga: process.env.VUE_APP_RENGA_INSTALLER_LINK || null,
    instructionLink: process.env.VUE_APP_REVIT_INSTRUCTION_LINK || null,
    demoLink: process.env.VUE_APP_REVIT_DEMO_LINK || null,
  }),
  computed: {
    ...mapState('revit', [
      'syncStatus',
      'applyStatus',
      'checkStatus',
      'pluginVersion',
      'revitVersion',
      'revitSyncStatus',
      'openedDocuments',
    ]),
    connecting: (vm) => vm.syncStatus === 2,
    connected: (vm) => vm.syncStatus === 3,
    applying: (vm) => vm.applyStatus === 2,
    checking: (vm) => some(vm.checkStatus, (status) => status === 2),
    btnText: (vm) => {
      if (vm.connecting) return 'Идет соединение...'

      return vm.connected ? 'Связь установлена' : 'Не подключено'
    },
    text: (vm) => {
      const status = vm.revitSyncStatus
      const hasPlugin = !!vm.pluginVersion
      const isOpen = !!vm.openedDocuments?.length

      if (!hasPlugin) return 'Плагин не найден.'
      else if (!isOpen)
        return 'САПР с плагином обнаружен, но не открыто ни одного файла.'

      const variants = {
        Busy: 'САПР занят.',
        Failed: 'Запрос не удался.',
      }

      return variants[status] ?? (vm.connected ? 'Подключено' : 'Не подключено')
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.connect()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('revit', ['syncWithRevit']),
    connect() {
      if (!this.connecting && !this.applying && !this.checking)
        this.syncWithRevit()
    },
  },
}
</script>
