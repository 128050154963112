import Model from '@/models/Model'
import { cloneDeep } from 'lodash'
import { v4 as UUIDv4 } from 'uuid'

export default class FilterCondition extends Model {
  static model = {
    Operator: 'And',
    Category: '',
    Property: '',
    Condition: '',
    Value: '',
  }

  static getModel(
    modelValues = {},
    model = this.model,
    toCamelCaseDeep = false
  ) {
    const resultModel = super.getModel(modelValues, model, toCamelCaseDeep)

    if (!modelValues.modelUUID) resultModel.modelUUID = UUIDv4()

    return resultModel
  }

  static prepareOriginCaseModel(model) {
    let cloned = cloneDeep(model)

    if (cloned.modelUUID) delete cloned.modelUUID
    if (cloned.isMy) delete cloned.isMy

    return super.prepareOriginCaseModel(cloned)
  }

  static prepareCamelCaseModel(modelValues = {}) {
    let cloned = cloneDeep(modelValues)

    if (cloned.modelUUID) delete cloned.modelUUID
    if (cloned.isMy) delete cloned.isMy

    return super.getModel(cloned, undefined, true)
  }
}
