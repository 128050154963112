/**
 * API: https://irma.bimlib.ru/redoc/#tag/EIR_SETS
 * */

import { vTableOptionsPrepare } from '@/utils/storeHelpers'
import { DEFAULT_QUERY_PARAMS } from '@/store/constants'

const endpoint = 'v1/eir_sets'

export default {
  namespaced: true,
  state: () => ({
    eirSetsTotal: 0,
    eirSets: [],
    eirSetsOptions: {},
    eirSetInfo: {},
    eirSetFilters: {},
  }),
  getters: {
    checkIsPublished: (state) => {
      const last = state.eirSetInfo?.versions?.[0] ?? null
      return last && last?.status === 'published'
    },
    publishedSetsOnly: (state) =>
      state.eirSets.filter(
        ({ versions }) => versions?.[0]?.status === 'published'
      ),
  },
  mutations: {
    setEirSetsTotal(state, number) {
      state.eirSetsTotal = number
    },
    setEirSets(state, array) {
      state.eirSets = array
    },
    setEirSetsOptions(state, object) {
      state.eirSetsOptions = object
    },
    setEirSetInfo(state, object) {
      state.eirSetInfo = object
    },
    setEirSetFilters(state, object) {
      state.eirSetFilters = object
    },
  },
  actions: {
    /**
     * @description Получение списка всех наборов требований, доступных организации
     * @param _context
     * @param options {object|null} Объект опций для таблиц vuetify
     * @param filters {object|null} Объект параметров запроса
     * */
    async fetchEirSets(
      { commit, state },
      { options = null, filters = null } = {}
    ) {
      if (options) commit('setEirSetsOptions', vTableOptionsPrepare(options))
      if (filters) commit('setEirSetFilters', filters)

      const { data } = await this.$api(endpoint, {
        params: {
          ...DEFAULT_QUERY_PARAMS,
          ...state.eirSetFilters,
          ...state.eirSetsOptions,
        },
      })

      commit('setEirSets', data?.results ?? data ?? [])
      commit('setEirSetsTotal', data?.count ?? data.length ?? 0)
    },

    /**
     * @description Получение информации о конкретном наборе требований
     * */
    async fetchSet({ commit }, id) {
      const { data } = await this.$api(`${endpoint}/${id}`)
      commit('setEirSetInfo', data)
    },

    /**
     * @description Создание нового набора требований
     * */
    async createEirSet(_, data) {
      await this.$api.post(endpoint, data)
    },

    /**
     * @description Частичное обновление информации о наборе требований
     * */
    async updateEirSet(_, data = {}) {
      await this.$api.patch(`${endpoint}/${data.id}`, data)
    },

    /**
     * @description Удаление набора требований
     * */
    async deleteEirSet(_, id) {
      await this.$api.delete(`${endpoint}/${id}`)
    },

    /**
     * @description Копирование набора требований
     * */
    copyEirSet(_, { id, name }) {
      return this.$api.post(`${endpoint}/${id}/copy`, { name })
    },

    /**
     * @description Публикация новой версии набора требований
     * */
    publishEirSet(_, { id, version, comment }) {
      return this.$api.post(`${endpoint}/${id}/publish`, {
        version,
        comment,
      })
    },
    /**
     * @description Импорт требований и параметров в набор
     * */
    importEirSet(_, { setId, file }) {
      const formData = new FormData()
      formData.append('file', file, file.name)

      return this.$api.post(`${endpoint}/${setId}/load`, formData)
    },
  },
}
