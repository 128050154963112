/**
 * API: https://irma.bimlib.ru/redoc/#tag/PROJECTS
 * */

import { vTableOptionsPrepare } from '@/utils/storeHelpers'
import { DEFAULT_QUERY_PARAMS } from '@/store/constants'

export default {
  namespaced: true,
  state: () => ({
    project: {},
    projects: [],
    projectsTotal: 0,
    projectsOptions: {},
    projectsFilters: {},
  }),
  mutations: {
    setProjects(state, array) {
      state.projects = array
    },
    setProjectsTotal(state, number) {
      state.projectsTotal = number
    },
    setProjectsOptions(state, object) {
      state.projectsOptions = object
    },
    setProjectsFilters(state, object) {
      state.projectsFilters = object
    },
    setProject(state, payload) {
      state.project = payload
    },
  },
  actions: {
    /**
     * @description Получение списка всех проектов организации
     * @param _context
     * @param options {object|null} Объект опций для таблиц vuetify
     * @param filters {object|null} Объект параметров запроса
     * */
    async fetchProjects(
      { commit, state },
      { options = null, filters = null } = {}
    ) {
      if (options) commit('setProjectsOptions', vTableOptionsPrepare(options))
      if (filters) commit('setProjectsFilters', filters)

      const { data } = await this.$api('v1/projects', {
        params: {
          ...DEFAULT_QUERY_PARAMS,
          ...state.projectsFilters,
          ...state.projectsOptions,
        },
      })

      commit('setProjects', data?.results ?? data ?? [])
      commit('setProjectsTotal', data?.count ?? data.length ?? 0)
    },
    /**
     * @description Создание нового проекта
     * */
    async createProject(_, data) {
      await this.$api.post('v1/projects', data)
    },
    /**
     * @description Частичное обновление информации о проекте
     * */
    async updateProject(_, data) {
      await this.$api.patch(`v1/projects/${data.id}`, data)
    },
    /**
     * @description Удаление проекта
     * */
    async deleteProject(_, id) {
      await this.$api.delete(`v1/projects/${id}`)
    },

    // TODO копирование проекта на бэке ещё не готово
    async copyProject(_, fields) {
      await this.$api.post('rpc/copy_project', fields)
    },
    /**
     * @description Получение информации о конкретном проекте
     * */
    async fetchProject({ commit }, id) {
      const { data } = await this.$api(`v1/projects/${id}`)
      commit('setProject', data)
    },
    /**
     * @description Добавление пользователей к проекту
     * @param _
     * @param id {number} ID проекта
     * @param emails {array} массив с объектами формата {email: ''}
     * */
    async addUsers(_, { id, emails = [] }) {
      await this.$api.post(`v1/projects/${id}/add_users`, emails)
    },
    /**
     * @description Удаление пользователей из проекта
     * @param _
     * @param id {number} ID проекта
     * @param emails {array} массив с объектами формата {email: ''}
     * */
    async delUsers(_, { id, emails = [] }) {
      await this.$api.post(`v1/projects/${id}/del_users`, emails)
    },
    /**
     * @description Добавление набора требований к проекту
     * @param _
     * @param id {number} ID проекта
     * @param eirSetsIds {array} массива с объектами ID набора требований формата {id: ''}
     * */
    async addEirSet(_, { id, eirSetsIds }) {
      await this.$api.post(`v1/projects/${id}/add_eir_set`, eirSetsIds)
    },
    /**
     * @description Удаление набора требований из проекта
     * @param _
     * @param id {number} ID проекта
     * @param eirSetsIds {array} массива с объектами ID набора требований формата {id: ''}
     * */
    async delEirSet(_, { id, eirSetsIds }) {
      await this.$api.post(`v1/projects/${id}/del_eir_set`, eirSetsIds)
    },
  },
}
