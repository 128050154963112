// Категория
export default [
  'Name',
  'OST_StackedWalls_Obsolete_IdInWrongRange',
  'OST_MassTags_Obsolete_IdInWrongRange',
  'OST_MassSurface_Obsolete_IdInWrongRange',
  'OST_MassFloor_Obsolete_IdInWrongRange',
  'OST_Mass_Obsolete_IdInWrongRange',
  'OST_WallRefPlanes_Obsolete_IdInWrongRange',
  'OST_StickSymbols_Obsolete_IdInWrongRange',
  'OST_RemovedGridSeg_Obsolete_IdInWrongRange',
  'OST_PointClouds',
  'OST_AssemblyOrigin_Lines',
  'OST_AssemblyOrigin_Planes',
  'OST_AssemblyOrigin_Points',
  'OST_AssemblyOrigin',
  'OST_LinksAnalytical',
  'OST_FoundationSlabAnalyticalTags',
  'OST_WallFoundationAnalyticalTags',
  'OST_IsolatedFoundationAnalyticalTags',
  'OST_WallAnalyticalTags',
  'OST_FloorAnalyticalTags',
  'OST_ColumnAnalyticalTags',
  'OST_BraceAnalyticalTags',
  'OST_BeamAnalyticalTags',
  'OST_AnalyticalNodes_Lines',
  'OST_AnalyticalNodes_Planes',
  'OST_AnalyticalNodes_Points',
  'OST_AnalyticalNodes',
  'OST_RigidLinksAnalytical',
  'OST_FoundationSlabAnalytical',
  'OST_WallFoundationAnalytical',
  'OST_IsolatedFoundationAnalytical',
  'OST_WallAnalytical',
  'OST_FloorAnalytical',
  'OST_ColumnEndSegment',
  'OST_ColumnStartSegment',
  'OST_ColumnAnalytical',
  'OST_BraceEndSegment',
  'OST_BraceStartSegment',
  'OST_BraceAnalytical',
  'OST_BeamEndSegment',
  'OST_BeamStartSegment',
  'OST_BeamAnalytical',
  'OST_CompassSecondaryMonth',
  'OST_CompassPrimaryMonth',
  'OST_CompassSectionFilled',
  'OST_LightLine',
  'OST_MultiSurface',
  'OST_SunSurface',
  'OST_Analemma',
  'OST_SunsetText',
  'OST_CompassSection',
  'OST_CompassOuter',
  'OST_SunriseText',
  'OST_CompassInner',
  'OST_SunPath2',
  'OST_SunPath1',
  'OST_Sun',
  'OST_SunStudy',
  'OST_StructuralTrussStickSymbols',
  'OST_StructuralTrussHiddenLines',
  'OST_TrussChord',
  'OST_TrussWeb',
  'OST_TrussBottomChordCurve',
  'OST_TrussTopChordCurve',
  'OST_TrussVertWebCurve',
  'OST_TrussDiagWebCurve',
  'OST_Truss',
  'OST_RailingSystemTransitionHiddenLines_Deprecated',
  'OST_RailingSystemTerminationHiddenLines_Deprecated',
  'OST_RailingSystemRailHiddenLines_Deprecated',
  'OST_RailingSystemTopRailHiddenLines_Deprecated',
  'OST_RailingSystemHandRailBracketHiddenLines_Deprecated',
  'OST_RailingSystemHandRailHiddenLines_Deprecated',
  'OST_RailingSystemPanelBracketHiddenLines_Deprecated',
  'OST_RailingSystemPanelHiddenLines_Deprecated',
  'OST_RailingSystemBalusterHiddenLines_Deprecated',
  'OST_RailingSystemPostHiddenLines_Deprecated',
  'OST_RailingSystemSegmentHiddenLines_Deprecated',
  'OST_RailingSystemHiddenLines_Deprecated',
  'OST_StairStringer2012HiddenLines_Deprecated',
  'OST_StairTread2012HiddenLines_Deprecated',
  'OST_StairLanding2012HiddenLines_Deprecated',
  'OST_StairRun2012HiddenLines_Deprecated',
  'OST_Stairs2012HiddenLines_Deprecated',
  'OST_MassHiddenLines',
  'OST_CurtaSystemHiddenLines',
  'OST_OBSOLETE_ElemArrayHiddenLines',
  'OST_EntourageHiddenLines',
  'OST_PlantingHiddenLines',
  'OST_SpecialityEquipmentHiddenLines',
  'OST_TopographyHiddenLines',
  'OST_StructuralFramingSystemHiddenLines_Obsolete',
  'OST_SiteHiddenLines',
  'OST_RoadsHiddenLines',
  'OST_ParkingHiddenLines',
  'OST_PlumbingFixturesHiddenLines',
  'OST_MechanicalEquipmentHiddenLines',
  'OST_LightingFixturesHiddenLines',
  'OST_FurnitureSystemsHiddenLines',
  'OST_ElectricalFixturesHiddenLines',
  'OST_ElectricalEquipmentHiddenLines',
  'OST_CaseworkHiddenLines',
  'OST_DetailComponentsHiddenLines',
  'OST_ShaftOpeningHiddenLines',
  'OST_GenericModelHiddenLines',
  'OST_CurtainWallMullionsHiddenLines',
  'OST_CurtainWallPanelsHiddenLines',
  'OST_RampsHiddenLines',
  'OST_StairsRailingHiddenLines',
  'OST_StairsHiddenLines',
  'OST_ColumnsHiddenLines',
  'OST_FurnitureHiddenLines',
  'OST_LinesHiddenLines',
  'OST_CeilingsHiddenLines',
  'OST_RoofsHiddenLines',
  'OST_DoorsHiddenLines',
  'OST_WindowsHiddenLines',
  'OST_RebarHiddenLines',
  'OST_StructConnectionTags',
  'OST_StructConnectionHiddenLines',
  'OST_StructWeldLines',
  'OST_StructConnections',
  'OST_FabricAreaBoundary',
  'OST_FabricReinSpanSymbol',
  'OST_FabricReinforcementWire',
  'OST_FabricReinforcementBoundary',
  'OST_RebarSetToggle',
  'OST_FabricAreaTags',
  'OST_FabricReinforcementTags',
  'OST_AreaReinTags',
  'OST_RebarTags',
  'OST_FabricAreaSketchSheetsLines',
  'OST_FabricAreaSketchEnvelopeLines',
  'OST_FabricAreas',
  'OST_FabricReinforcement',
  'OST_RebarCover',
  'OST_CoverType',
  'OST_RebarShape',
  'OST_PathReinBoundary',
  'OST_PathReinTags',
  'OST_PathReinSpanSymbol',
  'OST_PathRein',
  'OST_Cage',
  'OST_AreaReinXVisibility',
  'OST_AreaReinBoundary',
  'OST_AreaReinSpanSymbol',
  'OST_AreaReinSketchOverride',
  'OST_AreaRein',
  'OST_RebarLines',
  'OST_RebarSketchLines',
  'OST_Rebar',
  'OST_PipeFabricationPartsInsulation',
  'OST_LayoutNodes',
  'OST_PipeFabricationPartsCenterLine',
  'OST_FabricationServiceElements',
  'OST_PipeFabricationPartTags',
  'OST_PipeFabricationParts',
  'OST_LayoutPathBase_Pipings',
  'OST_NumberingSchemas',
  'OST_DivisionRules',
  'OST_gbXML_Shade',
  'OST_AnalyticSurfaces',
  'OST_AnalyticSpaces',
  'OST_gbXML_OpeningAir',
  'OST_gbXML_NonSlidingDoor',
  'OST_gbXML_SlidingDoor',
  'OST_gbXML_OperableSkylight',
  'OST_gbXML_FixedSkylight',
  'OST_gbXML_OperableWindow',
  'OST_gbXML_FixedWindow',
  'OST_gbXML_UndergroundCeiling',
  'OST_gbXML_UndergroundSlab',
  'OST_gbXML_UndergroundWall',
  'OST_gbXML_SurfaceAir',
  'OST_gbXML_Ceiling',
  'OST_gbXML_InteriorFloor',
  'OST_gbXML_InteriorWall',
  'OST_gbXML_SlabOnGrade',
  'OST_gbXML_RaisedFloor',
  'OST_gbXML_Roof',
  'OST_gbXML_ExteriorWall',
  'OST_DivisionProfile',
  'OST_SplitterProfile',
  'OST_PipeSegments',
  'OST_GraphicalWarning_OpenConnector',
  'OST_PlaceHolderPipes',
  'OST_PlaceHolderDucts',
  'OST_PipingSystem_Reference_Visibility',
  'OST_PipingSystem_Reference',
  'OST_DuctSystem_Reference_Visibility',
  'OST_DuctSystem_Reference',
  'OST_PipeInsulationsTags',
  'OST_DuctLiningsTags',
  'OST_DuctInsulationsTags',
  'OST_ElectricalInternalCircuits',
  'OST_PanelScheduleGraphics',
  'OST_CableTrayRun',
  'OST_ConduitRun',
  'OST_ParamElemElectricalLoadClassification',
  'OST_DataPanelScheduleTemplates',
  'OST_SwitchboardScheduleTemplates',
  'OST_BranchPanelScheduleTemplates',
  'OST_ConduitStandards',
  'OST_ElectricalLoadClassifications',
  'OST_ElectricalDemandFactorDefinitions',
  'OST_ConduitFittingCenterLine',
  'OST_CableTrayFittingCenterLine',
  'OST_ConduitCenterLine',
  'OST_ConduitDrop',
  'OST_ConduitRiseDrop',
  'OST_CableTrayCenterLine',
  'OST_CableTrayDrop',
  'OST_CableTrayRiseDrop',
  'OST_ConduitTags',
  'OST_Conduit',
  'OST_CableTrayTags',
  'OST_CableTray',
  'OST_ConduitFittingTags',
  'OST_ConduitFitting',
  'OST_CableTrayFittingTags',
  'OST_CableTrayFitting',
  'OST_RoutingPreferences',
  'OST_DuctLinings',
  'OST_DuctInsulations',
  'OST_PipeInsulations',
  'OST_HVAC_Load_Schedules',
  'OST_HVAC_Load_Building_Types',
  'OST_HVAC_Load_Space_Types',
  'OST_HVAC_Zones_Reference_Visibility',
  'OST_HVAC_Zones_InteriorFill_Visibility',
  'OST_HVAC_Zones_ColorFill',
  'OST_ZoneTags',
  'OST_LayoutPath_Bases',
  'OST_WireTemperatureRatings',
  'OST_WireInsulations',
  'OST_WireMaterials',
  'OST_HVAC_Zones_Reference',
  'OST_HVAC_Zones_InteriorFill',
  'OST_HVAC_Zones_Boundary',
  'OST_HVAC_Zones',
  'OST_Fluids',
  'OST_PipeSchedules',
  'OST_PipeMaterials',
  'OST_PipeConnections',
  'OST_EAConstructions',
  'OST_SwitchSystem',
  'OST_SprinklerTags',
  'OST_Sprinklers',
  'OST_RouteCurveBranch',
  'OST_RouteCurveMain',
  'OST_RouteCurve',
  'OST_GbXML_Opening',
  'OST_GbXML_SType_Underground',
  'OST_GbXML_SType_Shade',
  'OST_GbXML_SType_Exterior',
  'OST_GbXML_SType_Interior',
  'OST_GbXMLFaces',
  'OST_WireHomeRunArrows',
  'OST_LightingDeviceTags',
  'OST_LightingDevices',
  'OST_FireAlarmDeviceTags',
  'OST_FireAlarmDevices',
  'OST_DataDeviceTags',
  'OST_DataDevices',
  'OST_CommunicationDeviceTags',
  'OST_CommunicationDevices',
  'OST_SecurityDeviceTags',
  'OST_SecurityDevices',
  'OST_NurseCallDeviceTags',
  'OST_NurseCallDevices',
  'OST_TelephoneDeviceTags',
  'OST_TelephoneDevices',
  'OST_WireTickMarks',
  'OST_PipeFittingInsulation',
  'OST_PipeFittingCenterLine',
  'OST_FlexPipeCurvesInsulation',
  'OST_PipeCurvesInsulation',
  'OST_PipeCurvesDrop',
  'OST_DuctFittingLining',
  'OST_DuctFittingInsulation',
  'OST_DuctFittingCenterLine',
  'OST_FlexDuctCurvesInsulation',
  'OST_DuctCurvesLining',
  'OST_DuctCurvesInsulation',
  'OST_DuctCurvesDrop',
  'OST_DuctFittingTags',
  'OST_PipeFittingTags',
  'OST_PipeColorFills',
  'OST_PipeColorFillLegends',
  'OST_WireTags',
  'OST_PipeAccessoryTags',
  'OST_PipeAccessory',
  'OST_PipeCurvesRiseDrop',
  'OST_FlexPipeCurvesPattern',
  'OST_FlexPipeCurvesContour',
  'OST_FlexPipeCurvesCenterLine',
  'OST_FlexPipeCurves',
  'OST_PipeFitting',
  'OST_FlexPipeTags',
  'OST_PipeTags',
  'OST_PipeCurvesContour',
  'OST_PipeCurvesCenterLine',
  'OST_PipeCurves',
  'OST_PipingSystem',
  'OST_ElectricalDemandFactor',
  'OST_ElecDistributionSys',
  'OST_ElectricalVoltage',
  'OST_Wire',
  'OST_ElectricalCircuitTags',
  'OST_ElectricalCircuit',
  'OST_DuctCurvesRiseDrop',
  'OST_FlexDuctCurvesPattern',
  'OST_FlexDuctCurvesContour',
  'OST_FlexDuctCurvesCenterLine',
  'OST_FlexDuctCurves',
  'OST_DuctAccessoryTags',
  'OST_DuctAccessory',
  'OST_DuctSystem',
  'OST_DuctTerminalTags',
  'OST_DuctTerminal',
  'OST_DuctFitting',
  'OST_DuctColorFills',
  'OST_FlexDuctTags',
  'OST_DuctTags',
  'OST_DuctCurvesContour',
  'OST_DuctCurvesCenterLine',
  'OST_DuctCurves',
  'OST_DuctColorFillLegends',
  'OST_ConnectorElemZAxis',
  'OST_ConnectorElemYAxis',
  'OST_ConnectorElemXAxis',
  'OST_ConnectorElem',
  'OST_DesignOptions',
  'OST_DesignOptionSets',
  'OST_StructuralBracePlanReps',
  'OST_StructConnectionSymbols',
  'OST_StructuralAnnotations',
  'OST_RevisionCloudTags',
  'OST_Revisions',
  'OST_RevisionClouds',
  'OST_EditCutProfile',
  'OST_ElevationMarks',
  'OST_GridHeads',
  'OST_LevelHeads',
  'OST_DecalType',
  'OST_DecalElement',
  'OST_VolumeOfInterest',
  'OST_BoundaryConditions',
  'OST_InternalAreaLoadTags',
  'OST_InternalLineLoadTags',
  'OST_InternalPointLoadTags',
  'OST_AreaLoadTags',
  'OST_LineLoadTags',
  'OST_PointLoadTags',
  'OST_LoadCasesSeismic',
  'OST_LoadCasesTemperature',
  'OST_LoadCasesAccidental',
  'OST_LoadCasesRoofLive',
  'OST_LoadCasesSnow',
  'OST_LoadCasesWind',
  'OST_LoadCasesLive',
  'OST_LoadCasesDead',
  'OST_LoadCases',
  'OST_InternalAreaLoads',
  'OST_InternalLineLoads',
  'OST_InternalPointLoads',
  'OST_InternalLoads',
  'OST_AreaLoads',
  'OST_LineLoads',
  'OST_PointLoads',
  'OST_Loads',
  'OST_BeamSystemTags',
  'OST_FootingSpanDirectionSymbol',
  'OST_SpanDirectionSymbol',
  'OST_SpotSlopesSymbols',
  'OST_SpotCoordinateSymbols',
  'OST_SpotElevSymbols',
  'OST_TrussTags',
  'OST_KeynoteTags',
  'OST_DetailComponentTags',
  'OST_MaterialTags',
  'OST_FloorTags',
  'OST_CurtaSystemTags',
  'OST_HostFinTags',
  'OST_StairsTags',
  'OST_MultiCategoryTags',
  'OST_PlantingTags',
  'OST_AreaTags',
  'OST_StructuralFoundationTags',
  'OST_StructuralColumnTags',
  'OST_ParkingTags',
  'OST_SiteTags',
  'OST_StructuralFramingTags',
  'OST_SpecialityEquipmentTags',
  'OST_GenericModelTags',
  'OST_CurtainWallPanelTags',
  'OST_WallTags',
  'OST_PlumbingFixtureTags',
  'OST_MechanicalEquipmentTags',
  'OST_LightingFixtureTags',
  'OST_FurnitureSystemTags',
  'OST_FurnitureTags',
  'OST_ElectricalFixtureTags',
  'OST_ElectricalEquipmentTags',
  'OST_CeilingTags',
  'OST_CaseworkTags',
  'OST_Tags',
  'OST_MEPSpaceColorFill',
  'OST_MEPSpaceReference',
  'OST_MEPSpaceInteriorFill',
  'OST_MEPSpaceReferenceVisibility',
  'OST_MEPSpaceInteriorFillVisibility',
  'OST_MEPSpaces',
  'OST_StackedWalls',
  'OST_MassGlazingAll',
  'OST_MassFloorsAll',
  'OST_MassWallsAll',
  'OST_MassExteriorWallUnderground',
  'OST_MassSlab',
  'OST_MassShade',
  'OST_MassOpening',
  'OST_MassSkylights',
  'OST_MassGlazing',
  'OST_MassRoof',
  'OST_MassExteriorWall',
  'OST_MassInteriorWall',
  'OST_MassZone',
  'OST_MassAreaFaceTags',
  'OST_HostTemplate',
  'OST_MassFaceSplitter',
  'OST_MassCutter',
  'OST_ZoningEnvelope',
  'OST_MassTags',
  'OST_MassForm',
  'OST_MassFloor',
  'OST_Mass',
  'OST_DividedSurface_DiscardedDivisionLines',
  'OST_DividedSurfaceBelt',
  'OST_TilePatterns',
  'OST_AlwaysExcludedInAllViews',
  'OST_DividedSurface_TransparentFace',
  'OST_DividedSurface_PreDividedSurface',
  'OST_DividedSurface_PatternFill',
  'OST_DividedSurface_PatternLines',
  'OST_DividedSurface_Gridlines',
  'OST_DividedSurface_Nodes',
  'OST_DividedSurface',
  'OST_RepeatingDetailLines',
  'OST_RampsDownArrow',
  'OST_RampsUpArrow',
  'OST_RampsDownText',
  'OST_RampsUpText',
  'OST_RampsStringerAboveCut',
  'OST_RampsStringer',
  'OST_RampsAboveCut',
  'OST_RampsIncomplete',
  'OST_TrussDummy',
  'OST_ZoneSchemes',
  'OST_AreaSchemes',
  'OST_Areas',
  'OST_ProjectInformation',
  'OST_Sheets',
  'OST_ProfileFamilies',
  'OST_DetailComponents',
  'OST_RoofSoffit',
  'OST_EdgeSlab',
  'OST_Gutter',
  'OST_Fascia',
  'OST_Entourage',
  'OST_Planting',
  'OST_StructuralStiffenerHiddenLines',
  'OST_StructuralColumnLocationLine',
  'OST_StructuralFramingLocationLine',
  'OST_StructuralStiffenerTags',
  'OST_StructuralStiffener',
  'OST_FootingAnalyticalGeometry',
  'OST_RvtLinks',
  'OST_Automatic',
  'OST_SpecialityEquipment',
  'OST_ColumnAnalyticalRigidLinks',
  'OST_SecondaryTopographyContours',
  'OST_TopographyContours',
  'OST_TopographySurface',
  'OST_Topography',
  'OST_StructuralTruss',
  'OST_StructuralColumnStickSymbols',
  'OST_HiddenStructuralColumnLines',
  'OST_AnalyticalRigidLinks',
  'OST_ColumnAnalyticalGeometry',
  'OST_FramingAnalyticalGeometry',
  'OST_StructuralColumns',
  'OST_HiddenStructuralFramingLines',
  'OST_KickerBracing',
  'OST_StructuralFramingSystem',
  'OST_VerticalBracing',
  'OST_HorizontalBracing',
  'OST_Purlin',
  'OST_Joist',
  'OST_Girder',
  'OST_StructuralFramingOther',
  'OST_StructuralFraming',
  'OST_HiddenStructuralFoundationLines',
  'OST_StructuralFoundation',
  'OST_BasePointAxisZ',
  'OST_BasePointAxisY',
  'OST_BasePointAxisX',
  'OST_SharedBasePoint',
  'OST_ProjectBasePoint',
  'OST_SiteRegion',
  'OST_SitePropertyLineSegmentTags',
  'OST_SitePropertyLineSegment',
  'OST_SitePropertyTags',
  'OST_SitePointBoundary',
  'OST_SiteProperty',
  'OST_BuildingPad',
  'OST_SitePoint',
  'OST_SiteSurface',
  'OST_Site',
  'OST_HiddenBuildingUnitLines',
  'OST_BuildingUnits',
  'OST_Sewer',
  'OST_Roads',
  'OST_Property',
  'OST_Parking',
  'OST_PlumbingFixtures',
  'OST_MechanicalEquipment',
  'OST_LightingFixtureSource',
  'OST_LightingFixtures',
  'OST_FurnitureSystems',
  'OST_ElectricalFixtures',
  'OST_ElectricalEquipment',
  'OST_Casework',
  'OST_ArcWallRectOpening',
  'OST_DormerOpeningIncomplete',
  'OST_SWallRectOpening',
  'OST_ShaftOpening',
  'OST_StructuralFramingOpening',
  'OST_ColumnOpening',
  'OST_FndSlabLocalCoordSys',
  'OST_FloorLocalCoordSys',
  'OST_WallLocalCoordSys',
  'OST_BraceLocalCoordSys',
  'OST_ColumnLocalCoordSys',
  'OST_BeamLocalCoordSys',
  'OST_MultiReferenceAnnotations',
  'OST_DSR_LeaderTickMarkStyleId',
  'OST_DSR_InteriorTickMarkStyleId',
  'OST_DSR_ArrowHeadStyleId',
  'OST_DSR_CenterlineTickMarkStyleId',
  'OST_DSR_CenterlinePatternCatId',
  'OST_DSR_DimStyleHeavyEndCategoryId',
  'OST_DSR_DimStyleHeavyEndCatId',
  'OST_DSR_DimStyleTickCategoryId',
  'OST_DSR_LineAndTextAttrFontId',
  'OST_DSR_LineAndTextAttrCategoryId',
  'OST_NodeAnalyticalTags',
  'OST_LinkAnalyticalTags',
  'OST_RailingRailPathExtensionLines',
  'OST_RailingRailPathLines',
  'OST_StairsSupports',
  'OST_RailingHandRailAboveCut',
  'OST_RailingTopRailAboveCut',
  'OST_RailingTermination',
  'OST_RailingSupport',
  'OST_RailingHandRail',
  'OST_RailingTopRail',
  'OST_StairsSketchPathLines',
  'OST_StairsTriserNumbers',
  'OST_StairsTriserTags',
  'OST_StairsSupportTags',
  'OST_StairsLandingTags',
  'OST_StairsRunTags',
  'OST_StairsPathsAboveCut',
  'OST_StairsPaths',
  'OST_StairsRiserLinesAboveCut',
  'OST_StairsRiserLines',
  'OST_StairsOutlinesAboveCut',
  'OST_StairsOutlines',
  'OST_StairsNosingLinesAboveCut',
  'OST_StairsNosingLines',
  'OST_StairsCutMarksAboveCut',
  'OST_StairsCutMarks',
  'OST_ComponentRepeaterSlot',
  'OST_ComponentRepeater',
  'OST_DividedPath',
  'OST_IOSRoomCalculationPoint',
  'OST_PropertySet',
  'OST_AppearanceAsset',
  'OST_StairStringer2012_Deprecated',
  'OST_StairsTrisers',
  'OST_StairsLandings',
  'OST_StairsRuns',
  'OST_Stair2012_Deprecated',
  'OST_RailingSystemTags',
  'OST_RailingSystemTransition',
  'OST_RailingSystemTermination',
  'OST_RailingSystemRail',
  'OST_RailingSystemTopRail',
  'OST_RailingSystemHandRailBracket',
  'OST_RailingSystemHandRail',
  'OST_RailingSystemHardware',
  'OST_RailingSystemPanel',
  'OST_RailingSystemBaluster',
  'OST_RailingSystemPost',
  'OST_RailingSystemSegment',
  'OST_RailingSystem',
  'OST_AdaptivePoints_HiddenLines',
  'OST_AdaptivePoints_Lines',
  'OST_AdaptivePoints_Planes',
  'OST_AdaptivePoints_Points',
  'OST_AdaptivePoints',
  'OST_CeilingOpening',
  'OST_FloorOpening',
  'OST_RoofOpening',
  'OST_WallRefPlanes',
  'OST_StructLocationLineControl',
  'OST_DimLockControlLeader',
  'OST_MEPSpaceSeparationLines',
  'OST_AreaPolylines',
  'OST_RoomPolylines',
  'OST_InstanceDrivenLineStyle',
  'OST_RemovedGridSeg',
  'OST_IOSOpening',
  'OST_IOSTilePatternGrid',
  'OST_ControlLocal',
  'OST_ControlAxisZ',
  'OST_ControlAxisY',
  'OST_ControlAxisX',
  'OST_XRayConstrainedProfileEdge',
  'OST_XRayImplicitPathCurve',
  'OST_XRayPathPoint',
  'OST_XRayPathCurve',
  'OST_XRaySideEdge',
  'OST_XRayProfileEdge',
  'OST_ReferencePoints_HiddenLines',
  'OST_ReferencePoints_Lines',
  'OST_ReferencePoints_Planes',
  'OST_ReferencePoints_Points',
  'OST_ReferencePoints',
  'OST_Materials',
  'OST_CeilingsCutPattern',
  'OST_CeilingsDefault',
  'OST_CeilingsFinish2',
  'OST_CeilingsFinish1',
  'OST_CeilingsSubstrate',
  'OST_CeilingsInsulation',
  'OST_CeilingsStructure',
  'OST_CeilingsMembrane',
  'OST_FloorsInteriorEdges',
  'OST_FloorsCutPattern',
  'OST_HiddenFloorLines',
  'OST_FloorsDefault',
  'OST_FloorsFinish2',
  'OST_FloorsFinish1',
  'OST_FloorsSubstrate',
  'OST_FloorsInsulation',
  'OST_FloorsStructure',
  'OST_FloorsMembrane',
  'OST_RoofsInteriorEdges',
  'OST_RoofsCutPattern',
  'OST_RoofsDefault',
  'OST_RoofsFinish2',
  'OST_RoofsFinish1',
  'OST_RoofsSubstrate',
  'OST_RoofsInsulation',
  'OST_RoofsStructure',
  'OST_RoofsMembrane',
  'OST_WallsCutPattern',
  'OST_HiddenWallLines',
  'OST_WallsDefault',
  'OST_WallsFinish2',
  'OST_WallsFinish1',
  'OST_WallsSubstrate',
  'OST_WallsInsulation',
  'OST_WallsStructure',
  'OST_WallsMembrane',
  'OST_PreviewLegendComponents',
  'OST_LegendComponents',
  'OST_ScheduleGraphics',
  'OST_RasterImages',
  'OST_ColorFillSchema',
  'OST_RoomColorFill',
  'OST_ColorFillLegends',
  'OST_AnnotationCropSpecial',
  'OST_CropBoundarySpecial',
  'OST_AnnotationCrop',
  'OST_FloorsAnalyticalGeometry',
  'OST_WallsAnalyticalGeometry',
  'OST_CalloutLeaderLine',
  'OST_CeilingsSurfacePattern',
  'OST_RoofsSurfacePattern',
  'OST_FloorsSurfacePattern',
  'OST_WallsSurfacePattern',
  'OST_CalloutBoundary',
  'OST_CalloutHeads',
  'OST_Callouts',
  'OST_CropBoundary',
  'OST_Elev',
  'OST_AxisZ',
  'OST_AxisY',
  'OST_AxisX',
  'OST_CLines',
  'OST_Lights',
  'OST_ViewportLabel',
  'OST_Viewports',
  'OST_Camera_Lines',
  'OST_Cameras',
  'OST_MEPSpaceTags',
  'OST_RoomTags',
  'OST_DoorTags',
  'OST_WindowTags',
  'OST_SectionHeadWideLines',
  'OST_SectionHeadMediumLines',
  'OST_SectionHeadThinLines',
  'OST_SectionHeads',
  'OST_ContourLabels',
  'OST_CurtaSystemFaceManager',
  'OST_CurtaSystem',
  'OST_AreaReport_Arc_Minus',
  'OST_AreaReport_Arc_Plus',
  'OST_AreaReport_Boundary',
  'OST_AreaReport_Triangle',
  'OST_CurtainGridsCurtaSystem',
  'OST_CurtainGridsSystem',
  'OST_CurtainGridsWall',
  'OST_CurtainGridsRoof',
  'OST_DetailArray',
  'OST_ModelArray',
  'OST_HostFinHF',
  'OST_HostFinWall',
  'OST_HostFinCeiling',
  'OST_HostFinRoof',
  'OST_HostFinFloor',
  'OST_HostFin',
  'OST_AnalysisDisplayStyle',
  'OST_AnalysisResults',
  'OST_RenderRegions',
  'OST_SectionBox',
  'OST_TextNotes',
  'OST_Divisions',
  'OST_Catalogs',
  'OST_DirectionEdgeLines',
  'OST_CenterLines',
  'OST_LinesBeyond',
  'OST_HiddenLines',
  'OST_DemolishedLines',
  'OST_OverheadLines',
  'OST_TitleBlockWideLines',
  'OST_TitleBlockMediumLines',
  'OST_TitleBlockThinLines',
  'OST_TitleBlocks',
  'OST_Views',
  'OST_Viewers',
  'OST_PartHiddenLines',
  'OST_PartTags',
  'OST_Parts',
  'OST_AssemblyTags',
  'OST_Assemblies',
  'OST_RoofTags',
  'OST_SpotSlopes',
  'OST_SpotCoordinates',
  'OST_SpotElevations',
  'OST_Constraints',
  'OST_WeakDims',
  'OST_Dimensions',
  'OST_Levels',
  'OST_DisplacementPath',
  'OST_DisplacementElements',
  'OST_GridChains',
  'OST_Grids',
  'OST_BrokenSectionLine',
  'OST_SectionLine',
  'OST_Sections',
  'OST_ReferenceViewer',
  'OST_ReferenceViewerSymbol',
  'OST_ImportObjectStyles',
  'OST_ModelText',
  'OST_MaskingRegion',
  'OST_Matchline',
  'OST_FaceSplitter',
  'OST_PlanRegion',
  'OST_FilledRegion',
  'OST_MassingProjectionOutlines',
  'OST_MassingCutOutlines',
  'OST_Massing',
  'OST_Reveals',
  'OST_Cornices',
  'OST_Ramps',
  'OST_RailingBalusterRailCut',
  'OST_RailingBalusterRail',
  'OST_Railings',
  'OST_CurtainGrids',
  'OST_CurtainWallMullionsCut',
  'OST_CurtainWallMullions',
  'OST_CurtainWallPanels',
  'OST_AreaReference',
  'OST_AreaInteriorFill',
  'OST_RoomReference',
  'OST_RoomInteriorFill',
  'OST_AreaColorFill',
  'OST_AreaReferenceVisibility',
  'OST_AreaInteriorFillVisibility',
  'OST_RoomReferenceVisibility',
  'OST_RoomInteriorFillVisibility',
  'OST_Rooms',
  'OST_GenericModel',
  'OST_GenericAnnotation',
  'OST_Fixtures',
  'OST_StairsRailingTags',
  'OST_StairsRailingAboveCut',
  'OST_StairsDownArrows',
  'OST_StairsUpArrows',
  'OST_StairsDownText',
  'OST_StairsRailingRail',
  'OST_StairsRailingBaluster',
  'OST_StairsRailing',
  'OST_StairsUpText',
  'OST_StairsSupportsAboveCut',
  'OST_StairsStringerCarriage',
  'OST_StairsAboveCut_ToBeDeprecated',
  'OST_StairsIncomplete_Deprecated',
  'OST_Stairs',
  'OST_IOSNavWheelPivotBall',
  'OST_IOSRoomComputationHeight',
  'OST_IOSRoomUpperLowerLines',
  'OST_IOSDragBoxInverted',
  'OST_IOSDragBox',
  'OST_Phases',
  'OST_IOS_GeoSite',
  'OST_IOS_GeoLocations',
  'OST_IOSFabricReinSpanSymbolCtrl',
  'OST_GuideGrid',
  'OST_EPS_Future',
  'OST_EPS_Temporary',
  'OST_EPS_New',
  'OST_EPS_Demolished',
  'OST_EPS_Existing',
  'OST_IOSMeasureLineScreenSize',
  'OST_Columns',
  'OST_IOSRebarSystemSpanSymbolCtrl',
  'OST_IOSRoomTagToRoomLines',
  'OST_IOSAttachedDetailGroups',
  'OST_IOSDetailGroups',
  'OST_IOSModelGroups',
  'OST_IOSSuspendedSketch',
  'OST_IOSWallCoreBoundary',
  'OST_IOSMeasureLine',
  'OST_IOSArrays',
  'OST_Curtain_Systems',
  'OST_IOSBBoxScreenSize',
  'OST_IOSSlabShapeEditorPointInterior',
  'OST_IOSSlabShapeEditorPointBoundary',
  'OST_IOSSlabShapeEditorBoundary',
  'OST_IOSSlabShapeEditorAutoCrease',
  'OST_IOSSlabShapeEditorExplitCrease',
  'OST_ReferenceLines',
  'OST_IOSNotSilhouette',
  'OST_FillPatterns',
  'OST_Furniture',
  'OST_AreaSchemeLines',
  'OST_GenericLines',
  'OST_InsulationLines',
  'OST_CloudLines',
  'OST_IOSRoomPerimeterLines',
  'OST_IOSCuttingGeometry',
  'OST_IOSCrashGraphics',
  'OST_IOSGroups',
  'OST_IOSGhost',
  'OST_StairsSketchLandingCenterLines',
  'OST_StairsSketchRunLines',
  'OST_StairsSketchRiserLines',
  'OST_StairsSketchBoundaryLines',
  'OST_RoomSeparationLines',
  'OST_AxisOfRotation',
  'OST_InvisibleLines',
  'OST_IOSThinPixel_DashDot',
  'OST_IOSThinPixel_Dash',
  'OST_IOSThinPixel_Dot',
  'OST_Extrusions',
  'OST_IOS',
  'OST_CutOutlines',
  'OST_IOSThinPixel',
  'OST_IOSFlipControl',
  'OST_IOSSketchGrid',
  'OST_IOSSuspendedSketch_obsolete',
  'OST_IOSFreeSnapLine',
  'OST_IOSDatumPlane',
  'OST_Lines',
  'OST_IOSConstructionLine',
  'OST_IOSAlignmentGraphics',
  'OST_IOSAligningLine',
  'OST_IOSBackedUpElements',
  'OST_IOSRegeneratedElements',
  'OST_SketchLines',
  'OST_CurvesWideLines',
  'OST_CurvesMediumLines',
  'OST_CurvesThinLines',
  'OST_Curves',
  'OST_CeilingsProjection',
  'OST_CeilingsCut',
  'OST_Ceilings',
  'OST_RoofsProjection',
  'OST_RoofsCut',
  'OST_Roofs',
  'OST_FloorsProjection',
  'OST_FloorsCut',
  'OST_Floors',
  'OST_DoorsGlassProjection',
  'OST_DoorsGlassCut',
  'OST_DoorsFrameMullionProjection',
  'OST_DoorsFrameMullionCut',
  'OST_DoorsOpeningProjection',
  'OST_DoorsOpeningCut',
  'OST_DoorsPanelProjection',
  'OST_DoorsPanelCut',
  'OST_Doors',
  'OST_WindowsOpeningProjection',
  'OST_WindowsOpeningCut',
  'OST_WindowsSillHeadProjection',
  'OST_WindowsSillHeadCut',
  'OST_WindowsFrameMullionProjection',
  'OST_WindowsFrameMullionCut',
  'OST_WindowsGlassProjection',
  'OST_WindowsGlassCut',
  'OST_Windows',
  'OST_WallsProjectionOutlines',
  'OST_WallsCutOutlines',
  'OST_Walls',
  'OST_IOSRegenerationFailure',
  'OST_ScheduleViewParamGroup',
  'OST_MatchSiteComponent',
  'OST_MatchProfile',
  'OST_MatchDetail',
  'OST_MatchAnnotation',
  'OST_MatchModel',
  'OST_MatchAll',
].sort((a, b) => (a > b ? 1 : -1))
