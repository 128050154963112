import unionWithKey from '@/utils/unionWithKey'
import Vue from 'vue'

export default {
  updateRevitSyncStatus(state, status = '') {
    state.revitSyncStatus = status
  },
  updateOpenedDocuments(state, openedDocuments = []) {
    state.openedDocuments = openedDocuments
  },
  updatePluginVersion(state, version = '') {
    state.pluginVersion = version
  },
  updateRevitVersion(state, version = {}) {
    state.revitVersion = version
  },
  updateRevitElements(state, { eirElementId, elements, isTest }) {
    const key = !isTest ? 'revitElements' : 'testRevitElements'
    if (!state[key][eirElementId]) {
      Vue.set(state[key], eirElementId, elements)

      return
    }

    state[key][eirElementId] = elements
  },
  updateRevitElementsCount(
    state,
    { eirElementId, countMatch = null, countPresent = null }
  ) {
    if (!state.revitElementsCount[eirElementId]) {
      Vue.set(state.revitElementsCount, eirElementId, {
        countMatch,
        countPresent,
      })

      return
    }

    state.revitElementsCount[eirElementId] = {
      countMatch,
      countPresent,
    }
  },
  updateRevitParameters(state, parameters = []) {
    state.revitParameters = unionWithKey(
      parameters,
      state.revitParameters,
      'id',
      'name'
    )
  },
  updateRevitParametersCount(state, { paramName, countTotal, countPresent }) {
    if (!state.revitParametersCount[paramName]) {
      Vue.set(state.revitParametersCount, paramName, {
        countTotal,
        countPresent,
      })
    } else {
      state.revitParametersCount[paramName] = {
        countTotal:
          state.revitParametersCount[paramName].countTotal + countTotal,
        countPresent:
          state.revitParametersCount[paramName].countPresent + countPresent,
      }
    }
  },
  updateRevitCheckProgress(state, { eirElementId, progress }) {
    Vue.set(state.progress, eirElementId, progress)
  },
  updateActiveDocument(
    state,
    activeDocument = {
      title: '',
      filePath: '',
    }
  ) {
    state.activeDocument = activeDocument
  },
  updateCurrentCheckProject(state, projectId) {
    state.currentCheckProject = projectId
  },
  selectSaprElements(state, elements) {
    state.selectedSaprElements = elements
  },
  setFailedElementsIds(state, ids = []) {
    state.failedElementsIds = ids
  },
  updateDictionaries(state, { params, categories }) {
    state.builtInParams = params
    state.builtInCategories = categories
  },
  resetCreateStatus(state, eirElementId) {
    Object.entries(state.createStatus).forEach(([key]) => {
      if (key.includes(eirElementId)) delete state.createStatus[key]
    })
  },
  resetStateOfChecks(state) {
    state.revitElements = {}
    state.revitParameters = []
    state.revitElementsCount = {}
    state.revitParametersCount = {}
    state.progress = {}
    state.checkStatus = {}

    state.createStatus = {}

    state.applyStatus = 1
    state.failedElementsIds = []

    state.selectedSaprElements = []
    state.selectElementsStatus = 1

    state.testRevitElements = []
    state.testCheckStatus = []
  },
  resetStateOfTestChecks(state) {
    state.testRevitElements = []
    state.testCheckStatus = []
  },
  /*
   * Status switches
   * */
  switchSyncStatus(state, status) {
    state.syncStatus = status
  },
  switchCheckStatus(state, { eirElementId, status, isTest }) {
    const key = !isTest ? 'checkStatus' : 'testCheckStatus'

    if (!state[key][eirElementId]) Vue.set(state[key], eirElementId, 1)

    state[key][eirElementId] = status
  },
  switchApplyStatus(state, status) {
    state.applyStatus = status
  },
  switchChangeDocumentStatus(state, status) {
    state.changeDocumentStatus = status
  },
  switchSelectElementsStatus(state, status) {
    state.selectElementsStatus = status
  },
  switchCreateStatus(state, { eirElementId, paramName, status }) {
    const key = `${eirElementId}_${paramName}`

    if (!state.createStatus[key]) Vue.set(state.createStatus, key, 1)

    state.createStatus[key] = status
  },
}
