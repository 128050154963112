/**
 * API: https://irma.bimlib.ru/redoc/#tag/FILTERS
 * */

import { DEFAULT_CURRENT_FILTER } from '@/constants'
import {
  DEFAULT_QUERY_PARAMS,
  DEFAULT_NOTIFY_SNACKBAR,
} from '@/store/constants'

import { keysToCamelCaseDeep } from '@/utils/renameKeys'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as UUIDv4 } from 'uuid'

import FilterCondition from '@/models/FilterCondition'

const endpoint = 'v1/filters'

const state = () => ({
  templatesList: null,
  currentFilter: {},
  serverStateFilter: {},
  /*
   * 0 - error
   * 1 - idle
   * 2 - loading / saving / removing
   * 3 - success
   * */
  listStatus: 1,
  currentFilterStatus: 1,
  templateFilterStatus: 1,
})

const getters = {
  getTemplateFilter: (state) => (id) =>
    prepareFilter(state.templatesList?.find((item) => item.id === id)),
}

const mutations = {
  switchListStatus(state, status) {
    state.listStatus = status
  },
  updateFilterList(state, list) {
    state.templatesList = list
  },
  switchFilterStatus(state, status) {
    state.currentFilterStatus = status
  },
  switchTemplateFilterStatus(state, status) {
    state.templateFilterStatus = status
  },
  updateCurrentFilter(state, filter) {
    state.currentFilter = filter
  },
  resetCurrentFilter(state) {
    state.currentFilter = cloneDeep(DEFAULT_CURRENT_FILTER)
  },
  addEmptyCondition(state, options = {}) {
    let index = null
    const condition = {
      ...FilterCondition.getModel(options?.model ?? {}, undefined, true),
      isMy: true,
    }

    if (options.blockUUID) {
      condition.blockUUID = options.blockUUID

      if (!options.model) {
        const conditions = state.currentFilter.conditions

        index = conditions.findIndex((item, itemIndex) => {
          const nexItem = conditions[itemIndex + 1]

          return (
            options.blockUUID === item.blockUUID &&
            ((nexItem && item.blockUUID !== nexItem.blockUUID) || !nexItem)
          )
        })
      }
    }

    if (index !== null && index !== -1)
      state.currentFilter.conditions.splice(index + 1, 0, condition)
    else state.currentFilter.conditions.push(condition)
  },
  updateCondition(state, { index, condition }) {
    let item = cloneDeep(state.currentFilter.conditions[index])

    Object.keys(item).forEach((key) => {
      if (Object.hasOwnProperty.call(condition, key)) item[key] = condition[key]
    })

    state.currentFilter.conditions[index] = item
  },
  removeCondition(state, index) {
    state.currentFilter.conditions.splice(index, 1)
  },
  updateServerStateFilter(state, filter) {
    state.serverStateFilter = cloneDeep(filter)
  },
}

const actions = {
  /**
   * @description Получение списка всех шаблонов фильтров, доступных организации
   * */
  async getTemplateFilterList({ commit }, options = {}) {
    commit('switchListStatus', 2)
    try {
      const {
        data: { results },
      } = await this.$api.get(`${endpoint}/template`, {
        params: {
          ...DEFAULT_QUERY_PARAMS,
          ...options,
        },
      })

      commit('updateFilterList', keysToCamelCaseDeep(results))
      commit('switchListStatus', 1)
    } catch (e) {
      commit('switchListStatus', 0)
      this.$logger('error', '[filters/getTemplateFilterList]', e)
    }
  },
  /**
   * @description Получение информации о конкретном фильтре
   * */
  async getFilterById({ commit }, id) {
    if (isNaN(+id)) return

    commit('switchFilterStatus', 2)
    try {
      const { data } = await this.$api.get(`${endpoint}/${id}`)
      const filter = prepareFilter(data)

      commit('updateCurrentFilter', filter)
      commit('updateServerStateFilter', filter)
      commit('switchFilterStatus', 1)
    } catch (e) {
      commit('switchFilterStatus', 0)
      this.$logger('error', '[filters/getFilterById]', e)
    }
  },
  /**
   * @description Создание нового фильтра
   * */
  async createFilter(
    { commit, dispatch },
    { eirElementId, name = '1', sapr = 1, conditions, template }
  ) {
    if (
      (!name && !template) ||
      !conditions?.length ||
      (!eirElementId && !template)
    )
      return

    const switcherName = template
      ? 'switchTemplateFilterStatus'
      : 'switchFilterStatus'
    commit(switcherName, 2)

    try {
      let payload = {
        name,
        sapr,
        conditions: conditions.map((row) =>
          FilterCondition.prepareOriginCaseModel(row)
        ),
      }

      if (!template) payload.eir_element = eirElementId

      const path = template ? `${endpoint}/template` : endpoint
      const { data } = await this.$api.post(path, payload)

      if (!template) {
        const filter = prepareFilter(data)
        commit('updateCurrentFilter', filter)
        commit('updateServerStateFilter', filter)
      }

      commit(switcherName, 3)

      const options = {
        ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
        message: 'Фильтр успешно создан!',
        show: true,
      }
      commit('ui/triggerNotifySnackbar', options, { root: true })
      /*
       * обновляем информацию по текущему требованию,
       * там должен появиться id фильтра
       *  */
      if (!template)
        dispatch('eirElements/fetchEir', eirElementId, { root: true })

      if (template) this.$logActionEvent('Шаблон фильтра создан')
    } catch (e) {
      commit(switcherName, 0)
      this.$logger('error', '[filters/createFilter]', e)
    }
  },
  /**
   * @description Частичное обновление информации о конкретном фильтре
   * */
  async updateFilter(
    { commit },
    { id, eirElementId, name = '1', sapr = 1, conditions }
  ) {
    if (isNaN(+id) || !eirElementId || !conditions?.length) return

    commit('switchFilterStatus', 2)
    try {
      const { data } = await this.$api.patch(`${endpoint}/${id}`, {
        name,
        sapr,
        eir_element: eirElementId,
        conditions: conditions.map((row) =>
          FilterCondition.prepareOriginCaseModel(row)
        ),
      })
      const filter = prepareFilter(data)

      commit('updateCurrentFilter', filter)
      commit('updateServerStateFilter', filter)
      commit('switchFilterStatus', 3)

      const options = {
        ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
        message: 'Фильтр успешно обновлен!',
        show: true,
      }
      commit('ui/triggerNotifySnackbar', options, { root: true })
    } catch (e) {
      commit('switchFilterStatus', 0)
      this.$logger('error', '[filters/updateFilter]', e)
    }
  },
  /**
   * @description Удаление шаблона фильтра
   * */
  async removeTemplateFilter({ commit }, id) {
    if (isNaN(+id)) return

    commit('switchTemplateFilterStatus', 2)
    try {
      await this.$api.delete(`${endpoint}/template/${id}`)

      commit('switchTemplateFilterStatus', 3)

      const options = {
        ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
        message: 'Шаблон фильтра успешно удален!',
        show: true,
      }
      commit('ui/triggerNotifySnackbar', options, { root: true })

      this.$logActionEvent('Шаблон фильтра удалён')
    } catch (e) {
      commit('switchTemplateFilterStatus', 0)
      this.$logger('error', '[filters/removeFilter]', e)
    }
  },
}

function prepareFilter(filter) {
  if (!filter) return null

  let conditions = filter.conditions.map((row) =>
    FilterCondition.getModel(row, undefined, true)
  )
  delete filter.conditions
  /**
   * Заполняем пустующее поле оператора в первой строчке
   * */
  if (!conditions[0].operator) conditions[0].operator = 'And'
  /**
   * Добавляем блоки, если их нет
   * */
  if (!conditions[0].blockUUID) {
    let currentBlockUUID = UUIDv4()

    conditions.forEach((item, index) => {
      item.blockUUID = currentBlockUUID

      if (conditions[index + 1] && conditions[index + 1].operator !== 'And')
        currentBlockUUID = UUIDv4()
    })
  }

  return {
    ...keysToCamelCaseDeep(filter),
    conditions,
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
