// TODO: проверить, начинается ли бесконечный редирект при авторизации логином/паролем Сергея Припадчева
// TODO: лучше вынести всю авторизацию во vuex
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data: () => ({
    keycloakAuthObject: {
      url: process.env.VUE_APP_KEYCLOAK_END_POINT,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    },
  }),
  computed: {
    ...mapState(['keycloak']),
  },
  async mounted() {
    const keycloak = new window.Keycloak(this.keycloakAuthObject)
    this.setKeycloak(keycloak)

    this.keycloak.onAuthSuccess = () => this.setToken()
    this.keycloak.onAuthRefreshSuccess = () => this.setToken()
    this.keycloak.onTokenExpired = () => this.keycloak.updateToken()

    const token = localStorage.getItem('kc_token')
    const refreshToken = localStorage.getItem('kc_refreshToken')

    try {
      const auth = await this.keycloak.init({
        responseMode: 'fragment',
        flow: 'standard',
        onload: 'check-sso',
        pkceMethod: 'S256',
        token,
        refreshToken,
      })

      if (auth) {
        this.setUserInfo(this.keycloak.tokenParsed)
        this.$logActionEvent('Успешная авторизация')
      } else this.keycloak.login({ scope: '' })

      this.setAuth(auth)
    } catch (e) {
      this.$logger('error', '[authKeycloak]', e)
      this.keycloakLogout()
    }
  },
  methods: {
    ...mapMutations(['setAuth', 'setAuthToken', 'setUserInfo', 'setKeycloak']),
    ...mapActions(['keycloakLogout']),
    setToken() {
      localStorage.setItem('kc_token', this.keycloak.token)
      localStorage.setItem('kc_refreshToken', this.keycloak.refreshToken)

      this.$api.defaults.headers.common.Authorization = `Bearer ${this.keycloak.token}`
      this.setAuthToken(this.keycloak.token)
      this.setAuth(true)
    },
  },
}
