import pick from 'lodash/pick'
import { $log } from '@/utils/axiosInstance'

function logEvents({ state, getters }, name = 'pageview', options = {}) {
  const data = {
    user: {
      ...pick(state.userInfo, [
        'email',
        'email_verified',
        'name',
        'family_name',
        'given_name',
        'preferred_username',
        'iss',
      ]),
      admin: getters.isAdmin,
      client_id: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    },
    page: window.location.pathname,
    event: name,
    eventOptions: options,
    timestamp: new Date().getTime(),
  }
  return $log.post('/log', data)
}

export const logEventVuexPlugin = (store) => {
  store.$logActionEvent = logEvents.bind(null, store)
}

export default {
  install(Vue, store) {
    Vue.prototype.$logActionEvent = logEvents.bind(null, store)
  },
}
