/**
 * API: https://irma.bimlib.ru/redoc/#tag/EIR_INSTANCES
 * */

import cloneDeep from 'lodash/cloneDeep'
import {
  DEFAULT_QUERY_PARAMS,
  DEFAULT_EIR_INSTANCES_STATE,
} from '@/store/constants'
import { vTableOptionsPrepare } from '@/utils/storeHelpers'

export default {
  namespaced: true,

  state: () => cloneDeep(DEFAULT_EIR_INSTANCES_STATE),

  mutations: {
    setEirInstances(state, array) {
      state.eirInstances = array
    },
    setEirInstancesTotal(state, number) {
      state.eirInstancesTotal = number
    },
    setEirInstancesOptions(state, object) {
      state.eirInstancesOptions = object
    },
    setEirInstancesFilters(state, object) {
      state.eirInstancesFilters = object
    },
    resetEirInstances(state) {
      const defaultState = cloneDeep(DEFAULT_EIR_INSTANCES_STATE)

      Object.keys(defaultState).map((key) => (state[key] = defaultState[key]))
    },
  },

  actions: {
    /**
     * @description Получение списка всех экземпляров требований, доступных организации
     * @param _context
     * @param options {object|null} Объект опций для таблиц vuetify
     * @param filters {object|null} Объект параметров запроса
     * */
    async fetchEirInstances(
      { commit, state },
      { options = null, filters = null } = {}
    ) {
      if (options)
        commit('setEirInstancesOptions', vTableOptionsPrepare(options))
      if (filters) commit('setEirInstancesFilters', filters)

      const { data } = await this.$api('v1/eir_instances', {
        params: {
          ...DEFAULT_QUERY_PARAMS,
          ordering: '-id',
          ...state.eirInstancesFilters,
          ...state.eirInstancesOptions,
        },
      })
      commit('setEirInstances', data?.results ?? data ?? [])
      commit('setEirInstancesTotal', data?.count ?? data.length ?? 0)
    },
    /**
     * @description Получение информации о конкретном экземпляре требований
     * */
    async fetchInstanceById(_, instanceId) {
      return this.$api.get(`v1/eir_instances/${instanceId}/`)
    },
    /**
     * @description Создание нового экземпляра требований
     * */
    async createEirInstance(_, object) {
      return this.$api.post('v1/eir_instances', object)
    },
    /**
     * @description Частичное обновление информации об экземпляре требований
     * */
    async updateEirInstance(_, object) {
      return this.$api.patch(`v1/eir_instances/${object.id}`, object)
    },
    /**
     * @description Удаление экземпляра требований
     * */
    async deleteEirInstance(_, id) {
      await this.$api.delete(`v1/eir_instances/${id}`)
    },
    /**
     * @description Поиск по базе COSTX
     * */
    async searchInCostx(_, { title, start, limit }) {
      try {
        const { data } = await this.$costx.post('/search', {
          title,
          start,
          limit,
        })
        return data
      } catch (e) {
        this.$logger('error', '[eirInstances/searchInCostx]', e)
        return {}
      }
    },
  },
}
