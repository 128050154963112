/**
 * API: https://irma.bimlib.ru/redoc/#tag/SECTIONS
 * */

const endpoint = 'v1/sections'

export default {
  namespaced: true,
  state: () => ({
    sections: [],
    selectState: [],
  }),
  mutations: {
    setSections(state, array) {
      state.sections = array
    },
    setSectionSelectState(state, array) {
      state.selectState = array
    },
  },
  actions: {
    async fetchSections({ commit }, params) {
      try {
        const { data } = await this.$api
          .setTrace('sections/fetchSections')
          .get(endpoint, { params })
        commit('setSections', data)
      } catch (e) {
        this.$logger('error', '[sections/fetchSections]', e)
      }
    },
    async addParamsToSection(_, { sectionId, paramsIds }) {
      try {
        await this.$api
          .setTrace('sections/addParamsToSection')
          .post(`${endpoint}/${sectionId}/add_parameters`, paramsIds)
      } catch (e) {
        this.$logger('error', '[sections/addParamsToSection]', e)
      }
    },
    async removeParamsFromSection(_, { sectionId, paramsIds }) {
      try {
        await this.$api
          .setTrace('sections/removeParamsFromSection')
          .post(`${endpoint}/${sectionId}/del_parameters`, paramsIds)
      } catch (e) {
        this.$logger('error', '[sections/removeParamsFromSection]', e)
      }
    },
  },
}
