export default () => ({
  selectedSaprElements: [],
  /**
   * syncWithRevit
   * */
  activeDocument: {
    title: '',
    filePath: '', // полный путь к активному файлу
  },
  openedDocuments: [],
  revitSyncStatus: '', // состояние Ревита (Success / Failed / Busy)
  pluginVersion: '',
  revitVersion: {},
  /**
   * checkRevitProject
   * */
  currentCheckProject: null,
  progress: {}, // прогресс проверок по айдишкам требований
  revitElements: {}, // элементы Ревита по айдишкам требований
  revitElementsCount: {},
  revitParametersCount: {},
  revitParameters: [], // параметры Ревита
  /**
   * applyParamsRevit
   * */
  failedElementsIds: [],
  /**
   * Dictionaries
   * */
  builtInParams: [],
  builtInCategories: [],
  /**
   * 0 - error
   * 1 - idle
   * 2 - loading / applying / changing / selecting / creating
   * 3 - success
   * */
  syncStatus: 1,
  checkStatus: {}, // статусы проверки по айдишкам требований
  applyStatus: 1, // статус записи параметров
  createStatus: {}, // статусы создания параметра
  changeDocumentStatus: 1,
  selectElementsStatus: 1,
  /*
   * тестовые проверки
   * */
  testRevitElements: {}, // элементы Ревита по айдишкам требований
  testCheckStatus: {}, // статусы проверки по айдишкам требований
})
