import Model from '@/models/Model'

export default class ParameterSapr extends Model {
  static model = {
    REVIT_GUID: null,
    REVIT_TYPE: null,
    REVIT_DATA_TYPE: null,
    REVIT_PARAMETER_GROUP: null,
    REVIT_IS_SHARED: false,
    REVIT_IS_SYSTEM: null,
    REVIT_IS_READONLY: false,
    // renga
    rengaGUID: null,
    rengaParameterType: null,
    rengaParameterValueType: null,
  }
}
