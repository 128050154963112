<template>
  <v-app-bar
    class="toolbar"
    height="74px"
    elevation="0"
    :color="isDark ? '' : 'white'"
    app
  >
    <v-container class="d-flex justify-space-between pt-5">
      <TheLogo />

      <v-btn
        color="primary"
        text
        :class="['px-1 mr-3 ml-8', { active: pageType === 'project' }]"
        to="/"
      >
        <v-icon class="mr-2" size="22">mdi-table-of-contents</v-icon>
        <span>Проекты</span>
      </v-btn>
      <v-btn
        color="primary"
        text
        :class="['px-1 mr-3', { active: pageType === 'eir' }]"
        to="/eir-sets"
      >
        <v-icon class="mr-2" size="22">mdi-format-list-text</v-icon>
        <span>Информационные требования</span>
      </v-btn>
      <v-spacer />

      <TheSaprStatus />
      <v-menu
        v-model="showMenu"
        open-on-hover
        transition="slide-y-transition"
        offset-y
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            :color="isDark ? 'eee' : 'white'"
            v-bind="attrs"
            style="min-width: 0"
            depressed
            class="px-0 mt-1"
            v-on="on"
          >
            <v-avatar size="30">
              <v-icon :color="isAdmin ? 'warning' : ''">
                mdi-account-circle-outline
              </v-icon>
            </v-avatar>
            {{ getEmail }}
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item class="px-1">
              <v-chip
                class="mx-auto"
                small
                label
                :color="isAdmin ? 'warning' : 'primary'"
              >
                {{ role }}
              </v-chip>
            </v-list-item>
            <v-list-item v-if="isDev" class="px-1">
              <v-btn text @click="switchTheme">
                <v-icon small class="mr-2">
                  {{
                    isDark
                      ? 'mdi-white-balance-sunny'
                      : 'mdi-moon-waning-crescent'
                  }}
                </v-icon>
                <span>Смена темы</span>
              </v-btn>
            </v-list-item>
            <v-divider />
            <v-list-item class="px-1">
              <v-btn text class="mx-auto" @click="logoutFn">
                <v-icon class="mr-2" size="20">mdi-exit-to-app</v-icon>
                <span>Выход</span>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <TheHelpButton @show-modal="showHelp = !showHelp" />
      <TheHelpModal v-model="showHelp" />
    </v-container>
  </v-app-bar>
</template>

<script>
import TheHelpModal from '@/components/Layout/TheHelpModal'
import { mapGetters, mapMutations, mapState } from 'vuex'

import TheLogo from '@/components/Layout/TheLogo'
import TheSaprStatus from '@/components/Layout/TheSaprStatus'
import TheHelpButton from '@/components/Layout/TheHelpButton'

export default {
  name: 'TheHeader',
  components: { TheHelpModal, TheHelpButton, TheSaprStatus, TheLogo },
  props: {
    logoutFn: {
      type: Function,
      default: () => () => ({}),
    },
  },
  data: () => ({
    isDev: window.location.hostname === 'localhost',
    showMenu: false,
    showHelp: false,
  }),
  computed: {
    ...mapState('ui', ['isDark']),
    ...mapGetters(['isAdmin', 'isManager', 'getEmail']),
    pageType: (vm) => (vm.$route.path.includes('eir-sets') ? 'eir' : 'project'),
    role: (vm) => {
      const text = vm.isManager ? 'БИМ-менеджер' : 'Проектировщик'

      return vm.isAdmin ? 'Админ' : text
    },
  },
  watch: {
    isDark: {
      deep: true,
      immediate: true,
      handler(bool) {
        this.$vuetify.theme.isDark = bool
      },
    },
  },
  methods: {
    ...mapMutations('ui', ['switchTheme']),
  },
}
</script>

<style lang="scss" scoped>
.toolbar {
  & :deep(.v-toolbar__content) {
    padding: 0;
  }

  .active {
    background: rgba(0, 175, 161, 0.1);
  }
}
</style>
