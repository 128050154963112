/**
 * API: https://irma.bimlib.ru/redoc/#tag/USERS
 * */

export default {
  namespaced: true,

  state: {
    users: [],
  },

  mutations: {
    setUsers(state, payload) {
      state.users = payload
    },
  },

  actions: {
    /**
     * @description Получение списка всех пользователей своей организации
     * */
    async fetch({ commit }) {
      const { data } = await this.$api('v1/users')
      commit('setUsers', data)
    },
  },
}
