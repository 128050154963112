/**
 * API: https://irma.bimlib.ru/redoc/#tag/TAGS
 * */

const endpoint = 'v1/tags'

const state = () => ({
  tagList: [],
  selectedTags: [],
})

const mutations = {
  setList(state, array) {
    state.tagList = array
  },
  setSelectedTags(state, array) {
    state.selectedTags = array
  },
}

const actions = {
  async fetchTags({ commit }) {
    try {
      const { data } = await this.$api.get(endpoint)
      commit('setList', data)
    } catch (e) {
      this.$logger('error', '[tags/fetchTags]', e)
    }
  },
  async addTag({ dispatch }, tag) {
    try {
      await this.$api.post(`${endpoint}`, tag)
      await dispatch('fetchTags')
    } catch (e) {
      this.$logger('error', '[tags/addTag]', e)
    }
  },
  async deleteTag({ dispatch }, tagId) {
    try {
      await this.$api.delete(`${endpoint}/${tagId}`)
      await dispatch('fetchTags')
    } catch (e) {
      this.$logger('error', '[tags/deleteTag]', e)
    }
  },
  async linkTags(_context, { setId, tagIds }) {
    if (!tagIds?.length) return

    try {
      await this.$api.post(`v1/eir_sets/${setId}/add_tags/`, tagIds)
    } catch (e) {
      this.$logger('error', '[tags/linkTag]', e)
      return false
    }

    return true
  },
  async unlinkTags(_context, { setId, tagIds }) {
    if (!tagIds?.length) return

    try {
      await this.$api.post(`v1/eir_sets/${setId}/del_tags/`, tagIds)
    } catch (e) {
      this.$logger('error', '[tags/unlinkTags]', e)
      return false
    }

    return true
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
