<template>
  <v-app class="app">
    <template v-if="isAuth">
      <TheHeader :logout-fn="keycloakLogout" />
      <v-main>
        <v-container style="height: 100%">
          <router-view />
        </v-container>
      </v-main>

      <v-dialog
        v-model="error"
        :max-width="1000"
        :min-width="740"
        width="100%"
        persistent
        @keydown.esc="error = false"
      >
        <div class="error">
          <v-alert
            class="error__alert"
            border="right"
            colored-border
            elevation="2"
            type="error"
          >
            <pre>{{ errorText }}</pre>
          </v-alert>
          <v-btn
            class="mt-3"
            color="primary"
            depressed
            @click="errorModel = false"
          >
            Закрыть
          </v-btn>
        </div>
      </v-dialog>

      <MySnackbar />
    </template>
    <div v-else class="preloader">
      <v-progress-circular :size="75" color="primary" indeterminate />
    </div>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import TheHeader from '@/components/Layout/TheHeader'
import MySnackbar from '@/components/MySnackbar'

import authKeycloak from '@/mixins/authKeycloak'

export default {
  name: 'App',
  components: { MySnackbar, TheHeader },
  mixins: [authKeycloak],
  data: () => ({}),
  computed: {
    ...mapState(['isAuth', 'errorText', 'keycloak', 'error']),
    errorModel: {
      // TODO: переделать?
      get() {
        return this.error
      },
      set(value) {
        !value ? this.hideError() : this.showError(this.errorText)
      },
    },
  },
  created() {
    this.fromPluginHandler()
  },
  methods: {
    ...mapMutations(['showError', 'hideError']),
    ...mapActions(['keycloakLogout']),
    fromPluginHandler() {
      const fromPlugin = this.getUrlParams()?.from_plugin ?? ''

      if (fromPlugin) localStorage.setItem('from_plugin', fromPlugin)
    },
    getUrlParams() {
      const result = {}

      location.search
        .slice(1)
        .split('&')
        .forEach((item) => {
          const itemArr = item.split('=')
          result[itemArr[0]] = itemArr[1]
        })

      return result
    },
  },
}
</script>

<style lang="scss" scoped>
/*
  TODO: заменить ::v-deep на :deep()
  и раскидать стили по компонентам (или в файл отдельный, если глобальные),
  слишком много их тут
  */
.app {
  &.width-500::v-deep .container {
    max-width: 500px;
  }

  &::v-deep {
    .item-name {
      &:hover {
        text-decoration: none;
      }
    }

    .v-text-field--outlined fieldset {
      border: 1px solid rgba(77, 77, 77, 0.25);
    }

    .v-text-field--outlined.v-input--is-focused fieldset,
    .v-text-field--outlined.v-input--has-state fieldset {
      border: 1px solid currentColor;
    }

    .elevation-10 {
      box-shadow: 0 10px 10px 0 rgba(0, 175, 161, 0.2) !important;
    }

    .elevation-10.green-elevation {
      box-shadow: 0px 6px 6px -3px rgba(0, 175, 161, 0.2),
        0px 10px 14px 1px rgba(0, 175, 161, 0.1),
        0px 4px 18px 3px rgba(0, 175, 161, 0.05) !important;
    }

    .elevation-24.green-elevation {
      box-shadow: 0px 11px 15px -7px rgba(0, 175, 161, 0.3),
        0px 24px 38px 3px rgba(0, 175, 161, 0.2),
        0px 9px 46px 8px rgba(0, 175, 161, 0.1) !important;
    }

    .elevation-10.red-elevation {
      box-shadow: 0px 6px 6px -3px rgba(255, 80, 80, 0.2),
        0px 10px 14px 1px rgba(255, 80, 80, 0.1),
        0px 4px 18px 3px rgba(255, 80, 80, 0.05) !important;
    }

    .elevation-24.red-elevation {
      box-shadow: 0px 11px 15px -7px rgba(255, 80, 80, 0.3),
        0px 24px 38px 3px rgba(255, 80, 80, 0.2),
        0px 9px 46px 8px rgba(255, 80, 80, 0.1) !important;
    }

    .v-btn:not(.v-tab) {
      font-size: 14px;
      letter-spacing: normal;
      text-transform: initial;

      &::before {
        display: none;
      }
    }

    .v-btn:not(.v-btn--round).v-size--large {
      height: 42px;
      padding: 0 10px;
    }

    h1 {
      font-size: 24px;
    }

    .h1--18 {
      font-size: 18px;
    }

    h2 {
      font-size: 18px;
    }

    &:not(.theme--dark) {
      h1,
      h2 {
        color: #4d4d4d;
      }
    }

    .custom-table {
      .v-data-table-header th,
      .v-data-table__wrapper td {
        padding: 0 10px;
      }

      .v-data-table-header th {
        color: #4d4d4d !important;
      }

      .v-data-table-header th {
        font-size: 14px !important;
        border-bottom: 0 !important;
      }

      .v-data-table__wrapper table {
        border-spacing: 0 4px;
        border-collapse: separate;
      }

      .v-data-table__wrapper td {
        border-top: solid 1px #dbdbdb;
        border-bottom: solid 1px #dbdbdb;

        &:first-child {
          border-left: solid 1px #dbdbdb;
          border-radius: 5px 0 0 5px !important;
        }

        &:last-child {
          border-right: solid 1px #dbdbdb;
          border-radius: 0 5px 5px 0 !important;
        }

        &:only-child {
          border-radius: 5px !important;
        }
      }

      .v-data-table__wrapper tr {
        cursor: pointer;

        &:not(:hover) .lots-of-action {
          visibility: hidden;
        }
      }

      &:not(.theme--dark) .v-data-table__wrapper tr:hover td {
        background-color: #f0f0f0;
      }

      .v-data-footer {
        border-top: 0 !important;
      }

      &.v-data-table {
        position: relative;

        .lots-of-action {
          position: absolute;
          margin-top: -15px;
          padding: 5px;
          border-radius: 40px;
          right: 20px;
          background: #272727;
        }

        &:not(.theme--dark) .lots-of-action {
          background: white;
        }
      }
    }

    .custom-table-2 {
      table {
        border-spacing: 0;
        border-collapse: collapse;
      }

      &:not(.theme--dark) th {
        background-color: #f6f6f6;
      }

      td,
      th {
        height: 36px;
        padding: 0 10px;
        font-size: 14px;
        line-height: 1.4;
        border: 1px solid #eee !important;
      }

      .v-data-footer {
        border-top: 0;
      }
    }

    .tabs {
      z-index: 1;
      background: none;

      .v-tab {
        min-width: auto;
        font-size: 12px;
        font-weight: bold;
        padding: 0 0 15px;
        margin-right: 24px;

        &::before {
          display: none;
        }
      }

      &:not(.theme--dark) .v-tab {
        background: #fff;
      }

      .v-item-group {
        height: auto;
        background: none !important;
      }

      .v-slide-group__wrapper {
        padding-top: 20px;
      }

      .v-tabs-slider-wrapper {
        top: auto;
        bottom: 0;
      }

      .v-slide-group__prev,
      .v-slide-group__next {
        display: none !important;
      }
    }
  }

  .error {
    padding: 10px;
    position: relative;

    &__alert {
      margin-bottom: 0;
      border-bottom-left-radius: 0 !important;
    }

    &__close {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:not(.theme--dark) .error {
    background-color: #fff !important;

    &__alert {
      background-color: #fff;
    }
  }
}

.preloader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
html {
  overflow-y: auto !important;
}
</style>
