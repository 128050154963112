import { v4 as UUIDv4 } from 'uuid'
import FilterCondition from '@/models/FilterCondition'

export const DEFAULT_CURRENT_FILTER = {
  name: '1',
  sapr: 1,
  conditions: [
    {
      ...FilterCondition.getModel({}, undefined, true),
      isMy: true,
      blockUUID: UUIDv4(),
    },
  ],
}
