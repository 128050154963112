import store from '../store'
import axios from 'axios'
import { logger } from '@/utils/logger'

const headers = {}
if (store && store.state.authToken) {
  headers.Authorization = `Bearer ${store.state.authToken}`
}

export const $api = axios.create({
  timeout: 0,
  baseURL: process.env.VUE_APP_API_END_POINT,
  headers,
})

$api.prototype.constructor.setTrace = (traceName) => {
  $api.defaults.trace = traceName
  return $api
}

$api.interceptors.request.use((data) => {
  if (data.url.slice(-1) !== '/') data.url = data.url + '/'
  return data
})

// TODO возможно следует переписать обработчик ошибок на try catch и сделать обёртку
$api.interceptors.response.use(
  (resp) => resp,
  (error) => {
    const {
      response: { status, data },
    } = error

    let message = data?.message ?? data?.detail

    switch (status) {
      case 401:
      case 403: {
        store.dispatch('keycloakLogout')
        break
      }
      case 400: {
        message = Object.keys(data).reduce(
          (acc, key) => `${acc}${key}: ${(data[key] ?? [''])?.join('\n')}\n `,
          '\n '
        )
        break
      }
    }

    const { trace } = error.response.config

    if (message && trace !== 'eirElements/fetchEir')
      store.commit('showError', 'API: ' + message)

    if (trace) {
      logger('error', `[${trace}]`, error)
    }

    return Promise.reject(error)
  }
)

export const $log = axios.create({
  timeout: 0,
  baseURL: process.env.VUE_APP_LOG_END_POINT,
})
$log.interceptors.response.use(
  (resp) => resp,
  (error) => {
    store.commit('showError', 'logActionEvent: ' + error.response.data.message)
    return Promise.reject(error)
  }
)

const revitHost = process.env.VUE_APP_REVIT_HOST
export const $revit = axios.create({
  baseURL: revitHost,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  data: {},
})

export const $costx = axios.create({
  timeout: 0,
  baseURL: process.env.VUE_APP_COSTX_API,
  headers,
})

export const $catalogs = axios.create({
  timeout: 0,
  baseURL: process.env.VUE_APP_CATALOGS_API,
  headers,
})

export const axiosPlugin = (store) => {
  store.$api = $api
  store.$revit = $revit
  store.$costx = $costx
  store.$catalogs = $catalogs
}
