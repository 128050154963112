/**
 * API: https://irma.bimlib.ru/redoc/#tag/VERSIONS
 * */

const endpoint = 'v1/versions'

export default {
  namespaced: true,
  state: () => ({
    versions: [],
  }),
  getters: {
    getVersionById: (state) => (id) =>
      state.versions?.find((version) => version.id === id) ?? {},
  },
  mutations: {
    setVersions(state, array) {
      state.versions = array
    },
  },
  actions: {
    async fetchVersions({ commit }, params) {
      try {
        const {
          data: { results },
        } = await this.$api.setTrace('versions/fetchVersions').get(endpoint, {
          params: {
            limit: 1000,
            offset: 0,
            ...params,
          },
        })
        commit('setVersions', results)
      } catch (e) {
        this.$logger('error', '[versions/fetchVersions]', e)
      }
    },
  },
}
