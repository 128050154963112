// Единицы измерения
export default {
  DUT_UNDEFINED: 'Нет',
  DUT_METERS: 'Метры',
  DUT_CENTIMETERS: 'Сантиметры',
  DUT_MILLIMETERS: 'Миллиметры',
  DUT_DECIMAL_FEET: 'Десятичные футы',
  DUT_FEET_FRACTIONAL_INCHES: 'Футы и дробные дюймы',
  DUT_FRACTIONAL_INCHES: 'Дробные дюймы',
  DUT_DECIMAL_INCHES: 'Десятичные дюймы',
  DUT_ACRES: 'Акры',
  DUT_HECTARES: 'Гектары',
  DUT_METERS_CENTIMETERS: 'Метры и сантиметры',
  DUT_CUBIC_YARDS: 'Куб. ярды',
  DUT_SQUARE_FEET: 'Кв. футы',
  DUT_SQUARE_METERS: 'Кв. метры',
  DUT_CUBIC_FEET: 'Куб. футы',
  DUT_CUBIC_METERS: 'Куб. метры',
  DUT_DECIMAL_DEGREES: 'Десятичные градусы',
  DUT_DEGREES_AND_MINUTES: 'Градусы-минуты-секунды',
  DUT_GENERAL: 'Общие',
  DUT_FIXED: 'Фиксированные',
  DUT_PERCENTAGE: 'Процент',
  DUT_SQUARE_INCHES: 'Кв. дюймы',
  DUT_SQUARE_CENTIMETERS: 'Кв. сантиметры',
  DUT_SQUARE_MILLIMETERS: 'Кв. миллиметры',
  DUT_CUBIC_INCHES: 'Куб. дюймы',
  DUT_CUBIC_CENTIMETERS: 'Куб. сантиметры',
  DUT_CUBIC_MILLIMETERS: 'Куб. миллиметры',
  DUT_LITERS: 'Литры',
  DUT_GALLONS_US: 'Ам.галлоны',
  DUT_KILOGRAMS_PER_CUBIC_METER: 'Килограммы на кубический метр',
  DUT_POUNDS_MASS_PER_CUBIC_FOOT: 'Фунты на кубический фут',
  DUT_POUNDS_MASS_PER_CUBIC_INCH: 'Фунты на кубический дюйм',
  DUT_BRITISH_THERMAL_UNITS: 'Британские тепловые единицы',
  DUT_CALORIES: 'Калории',
  DUT_KILOCALORIES: 'Килокалории',
  DUT_JOULES: 'Джоули',
  DUT_KILOWATT_HOURS: 'Киловатт-часы',
  DUT_THERMS: 'Термы',
  DUT_INCHES_OF_WATER_PER_100FT: 'Дюймы водяного столба (60 °F) на 100 футов',
  DUT_PASCALS_PER_METER: 'Паскали на метр',
  DUT_WATTS: 'Ватты',
  DUT_KILOWATTS: 'Киловатты',
  DUT_BRITISH_THERMAL_UNITS_PER_SECOND: 'Британские тепловые единицы в секунду',
  DUT_BRITISH_THERMAL_UNITS_PER_HOUR: 'Британские тепловые единицы в час',
  DUT_CALORIES_PER_SECOND: 'Калории в секунду',
  DUT_KILOCALORIES_PER_SECOND: 'Килокалории в секунду',
  DUT_WATTS_PER_SQUARE_FOOT: 'Ватты на квадратный фут',
  DUT_WATTS_PER_SQUARE_METER: 'Ватты на квадратный метр',
  DUT_INCHES_OF_WATER: 'Дюймы водяного столба (60 °F)',
  DUT_PASCALS: 'Паскали',
  DUT_KILOPASCALS: 'Килопаскали',
  DUT_MEGAPASCALS: 'Мегапаскали',
  DUT_POUNDS_FORCE_PER_SQUARE_INCH: 'Фунты на квадратный дюйм',
  DUT_INCHES_OF_MERCURY: 'Дюймы ртутного столба (32 °F)',
  DUT_MILLIMETERS_OF_MERCURY: 'Миллиметры ртутного столба',
  DUT_ATMOSPHERES: 'Атмосферы',
  DUT_BARS: 'Бары',
  DUT_FAHRENHEIT: 'Градусы Фаренгейта',
  DUT_CELSIUS: 'Градусы Цельсия',
  DUT_KELVIN: 'Градусы Кельвина',
  DUT_RANKINE: 'Градусы Ренкина',
  DUT_FEET_PER_MINUTE: 'Футы в минуту',
  DUT_METERS_PER_SECOND: 'Метры в секунду',
  DUT_CENTIMETERS_PER_MINUTE: 'Сантиметры в минуту',
  DUT_CUBIC_FEET_PER_MINUTE: 'Кубические футы в минуту',
  DUT_LITERS_PER_SECOND: 'Литры в секунду',
  DUT_CUBIC_METERS_PER_SECOND: 'Кубические метры в секунду',
  DUT_CUBIC_METERS_PER_HOUR: 'Кубические метры в час',
  DUT_GALLONS_US_PER_MINUTE: 'Американские галлоны в минуту',
  DUT_GALLONS_US_PER_HOUR: 'Американские галлоны в час',
  DUT_AMPERES: 'Амперы',
  DUT_KILOAMPERES: 'Килоамперы',
  DUT_MILLIAMPERES: 'Миллиамперы',
  DUT_VOLTS: 'Вольты',
  DUT_KILOVOLTS: 'Киловольты',
  DUT_MILLIVOLTS: 'Милливольты',
  DUT_HERTZ: 'Герцы',
  DUT_CYCLES_PER_SECOND: 'Циклы в секунду',
  DUT_LUX: 'Люксы',
  DUT_FOOTCANDLES: 'Фут-канделы',
  DUT_FOOTLAMBERTS: 'Фут-ламберты',
  DUT_CANDELAS_PER_SQUARE_METER: 'Канделы на квадратный метр',
  DUT_CANDELAS: 'Канделы',
  DUT_LUMENS: 'Люмены',
  DUT_VOLT_AMPERES: 'Вольт-амперы',
  DUT_KILOVOLT_AMPERES: 'Киловольт-амперы',
  DUT_HORSEPOWER: 'Лошадиные силы',
  DUT_NEWTONS: 'Ньютоны',
  DUT_DECANEWTONS: 'Деканьютоны',
  DUT_KILONEWTONS: 'Килоньютоны',
  DUT_MEGANEWTONS: 'Меганьютоны',
  DUT_KIPS: 'Kips',
  DUT_KILOGRAMS_FORCE: 'Килограммы силы',
  DUT_TONNES_FORCE: 'Тонны силы',
  DUT_POUNDS_FORCE: 'Фунты',
  DUT_NEWTONS_PER_METER: 'Ньютоны на метр',
  DUT_DECANEWTONS_PER_METER: 'Деканьютоны на метр',
  DUT_KILONEWTONS_PER_METER: 'Килоньютоны на метр',
  DUT_MEGANEWTONS_PER_METER: 'Меганьютоны на метр',
  DUT_KIPS_PER_FOOT: 'Кипы на фут',
  DUT_KILOGRAMS_FORCE_PER_METER: 'Килограммы силы на метр',
  DUT_TONNES_FORCE_PER_METER: 'Тонны силы на метр',
  DUT_POUNDS_FORCE_PER_FOOT: 'Фунты на фут',
  DUT_NEWTONS_PER_SQUARE_METER: 'Ньютоны на квадратный метр',
  DUT_DECANEWTONS_PER_SQUARE_METER: 'Деканьютоны на квадратный метр',
  DUT_KILONEWTONS_PER_SQUARE_METER: 'Килоньютоны на квадратный метр',
  DUT_MEGANEWTONS_PER_SQUARE_METER: 'Меганьютоны на квадратный метр',
  DUT_KIPS_PER_SQUARE_FOOT: 'Кипы на квадратный фут',
  DUT_KILOGRAMS_FORCE_PER_SQUARE_METER: 'Килограммы силы на квадратный метр',
  DUT_TONNES_FORCE_PER_SQUARE_METER: 'Тонны силы на квадратный метр',
  DUT_POUNDS_FORCE_PER_SQUARE_FOOT: 'Фунты на квадратный фут',
  DUT_NEWTON_METERS: 'Ньютон-метры',
  DUT_DECANEWTON_METERS: 'Деканьютон-метры',
  DUT_KILONEWTON_METERS: 'Килоньютон-метры',
  DUT_MEGANEWTON_METERS: 'Меганьютон-метры',
  DUT_KIP_FEET: 'Кип-футы',
  DUT_KILOGRAM_FORCE_METERS: 'Килограмм силы-метры',
  DUT_TONNE_FORCE_METERS: 'Тонны силы-метры',
  DUT_POUND_FORCE_FEET: 'Фунты-футы',
  DUT_METERS_PER_KILONEWTON: 'Метры на килоньютон',
  DUT_FEET_PER_KIP: 'Футы на кип',
  DUT_SQUARE_METERS_PER_KILONEWTON: 'Квадратные метры на килоньютон',
  DUT_SQUARE_FEET_PER_KIP: 'Квадратные футы на кип',
  DUT_CUBIC_METERS_PER_KILONEWTON: 'Кубические метры на килоньютон',
  DUT_CUBIC_FEET_PER_KIP: 'Кубические футы на кип',
  DUT_INV_KILONEWTONS: '1 на килоньютон',
  DUT_INV_KIPS: '1 на кип',
  DUT_FEET_OF_WATER_PER_100FT: 'Футы воды (39,2 °F) на 100 футов',
  DUT_FEET_OF_WATER: 'Футы воды (39,2 °F)',
  DUT_PASCAL_SECONDS: 'Паскали на секунды',
  DUT_POUNDS_MASS_PER_FOOT_SECOND: 'Фунты на фут в секунду',
  DUT_CENTIPOISES: 'Сантипуазы',
  DUT_FEET_PER_SECOND: 'Футы в секунды',
  DUT_KIPS_PER_SQUARE_INCH: 'Кипы на квадратный дюйм',
  DUT_KILONEWTONS_PER_CUBIC_METER: 'Килоньютоны на кубический метр',
  DUT_POUNDS_FORCE_PER_CUBIC_FOOT: 'Фунты на кубический фут',
  DUT_KIPS_PER_CUBIC_INCH: 'Кипы на кубический дюйм',
  DUT_INV_FAHRENHEIT: '1/градусы по Фаренгейту',
  DUT_INV_CELSIUS: '1/градусы Цельсия',
  DUT_NEWTON_METERS_PER_METER: 'Ньютон-метры на метр',
  DUT_DECANEWTON_METERS_PER_METER: 'Деканьютон-метры на метр',
  DUT_KILONEWTON_METERS_PER_METER: 'Килоньютон-метры на метр',
  DUT_MEGANEWTON_METERS_PER_METER: 'Меганьютон-метры на метр',
  DUT_KIP_FEET_PER_FOOT: 'Кипы-футы на фут',
  DUT_KILOGRAM_FORCE_METERS_PER_METER: 'Килограмм силы-метры на метр',
  DUT_TONNE_FORCE_METERS_PER_METER: 'Тонны силы-метры на метр',
  DUT_POUND_FORCE_FEET_PER_FOOT: 'Фунты-футы на фут',
  DUT_POUNDS_MASS_PER_FOOT_HOUR: 'Фунты на фут в час',
  DUT_KIPS_PER_INCH: 'Кипы на дюйм',
  DUT_KIPS_PER_CUBIC_FOOT: 'Кипы на кубический дюйм',
  DUT_KIP_FEET_PER_DEGREE: 'Кипы-футы на градус',
  DUT_KILONEWTON_METERS_PER_DEGREE: 'Килоньютон-метры на градус',
  DUT_KIP_FEET_PER_DEGREE_PER_FOOT: 'Кипы-футы на градус на фут',
  DUT_KILONEWTON_METERS_PER_DEGREE_PER_METER:
    'Килоньютон-метры на градус на метр',
  DUT_WATTS_PER_SQUARE_METER_KELVIN:
    'Ватты на квадратный метр и градус Кельвина',
  DUT_BRITISH_THERMAL_UNITS_PER_HOUR_SQUARE_FOOT_FAHRENHEIT:
    'Британские тепловые единицы в час кв. футы градусы Фаренгейта',
  DUT_CUBIC_FEET_PER_MINUTE_SQUARE_FOOT:
    'Кубические футы в минуту на квадратный фут',
  DUT_LITERS_PER_SECOND_SQUARE_METER: 'Литры в секунду на квадратный метр',
  DUT_RATIO_10: 'Отношение: 10',
  DUT_RATIO_12: 'Отношение: 12',
  DUT_SLOPE_DEGREES: 'Десятичные градусы',
  DUT_RISE_OVER_INCHES: 'Подъем / 12"',
  DUT_RISE_OVER_FOOT: 'Подъем / 1\' - 0"',
  DUT_RISE_OVER_MMS: 'Подъем / 1000 мм',
  DUT_WATTS_PER_CUBIC_FOOT: 'Ватты на кубический фут',
  DUT_WATTS_PER_CUBIC_METER: 'Ватты на кубический метр',
  DUT_BRITISH_THERMAL_UNITS_PER_HOUR_SQUARE_FOOT:
    'БТЕ (британские тепловые единицы) в час на квадратный фут',
  DUT_BRITISH_THERMAL_UNITS_PER_HOUR_CUBIC_FOOT:
    'БТЕ (британские тепловые единицы) в час на кубический фут',
  DUT_TON_OF_REFRIGERATION:
    'Тонны охлаждения (12 000 БТЕ (британских тепловых единиц) в час)',
  DUT_CUBIC_FEET_PER_MINUTE_CUBIC_FOOT:
    'Кубические футы в минуту на кубический фут',
  DUT_LITERS_PER_SECOND_CUBIC_METER: 'Литры в секунду на кубический метр',
  DUT_CUBIC_FEET_PER_MINUTE_TON_OF_REFRIGERATION:
    'Кубические футы в минуту на тонну охлаждения',
  DUT_LITERS_PER_SECOND_KILOWATTS: 'Литры в секунду на киловатт',
  DUT_SQUARE_FEET_PER_TON_OF_REFRIGERATION:
    'Квадратный фут на тонну охлаждения',
  DUT_SQUARE_METERS_PER_KILOWATTS: 'Квадратные метры на киловатт',
  DUT_CURRENCY: 'Денежная единица',
  DUT_LUMENS_PER_WATT: 'Люмены на ватт',
  DUT_SQUARE_FEET_PER_THOUSAND_BRITISH_THERMAL_UNITS_PER_HOUR:
    'Квадратные футы на тысячу БТЕ (британская тепловая единица) в час',
  DUT_KILONEWTONS_PER_SQUARE_CENTIMETER: 'Килоньютоны на квадратный сантиметр',
  DUT_NEWTONS_PER_SQUARE_MILLIMETER: 'Ньютоны на квадратный миллиметр',
  DUT_KILONEWTONS_PER_SQUARE_MILLIMETER: 'Килоньютоны на квадратный миллиметр',
  DUT_RISE_OVER_120_INCHES: 'Подъем / 120"',
  DUT_1_RATIO: 'Отношение: 1',
  DUT_RISE_OVER_10_FEET: "Подъем / 10'",
  DUT_HOUR_SQUARE_FOOT_FAHRENHEIT_PER_BRITISH_THERMAL_UNIT:
    'час кв. футы градусы Фаренгейта на БТЕ (британская тепловая единица)',
  DUT_SQUARE_METER_KELVIN_PER_WATT: 'Квадратный метр Кельвин на Ватт',
  DUT_BRITISH_THERMAL_UNIT_PER_FAHRENHEIT: 'БТЕ на градусы Фаренгейта',
  DUT_JOULES_PER_KELVIN: 'Джоулей на градус Кельвина',
  DUT_KILOJOULES_PER_KELVIN: 'Килоджоулей на градус Кельвина',
  DUT_KILOGRAMS_MASS: 'Килограммы',
  DUT_TONNES_MASS: 'Тонны',
  DUT_POUNDS_MASS: 'Фунты',
  DUT_METERS_PER_SECOND_SQUARED: 'Метры на секунду в квадрате',
  DUT_KILOMETERS_PER_SECOND_SQUARED: 'Километры на секунду в квадрате',
  DUT_INCHES_PER_SECOND_SQUARED: 'Дюймы на секунду в квадрате',
  DUT_FEET_PER_SECOND_SQUARED: 'Футы на секунду в квадрате',
  DUT_MILES_PER_SECOND_SQUARED: 'Мили на секунду в квадрате',
  DUT_FEET_TO_THE_FOURTH_POWER: 'Футы в четвертой степени',
  DUT_INCHES_TO_THE_FOURTH_POWER: 'Дюймы в четвертой степени',
  DUT_MILLIMETERS_TO_THE_FOURTH_POWER: 'Миллиметры в четвертой степени',
  DUT_CENTIMETERS_TO_THE_FOURTH_POWER: 'Сантиметры в четвертой степени',
  DUT_METERS_TO_THE_FOURTH_POWER: 'Метры в четвертой степени',
  DUT_FEET_TO_THE_SIXTH_POWER: 'Футы в шестой степени',
  DUT_INCHES_TO_THE_SIXTH_POWER: 'Дюймы в шестой степени',
  DUT_MILLIMETERS_TO_THE_SIXTH_POWER: 'Миллиметры в шестой степени',
  DUT_CENTIMETERS_TO_THE_SIXTH_POWER: 'Сантиметры в шестой степени',
  DUT_METERS_TO_THE_SIXTH_POWER: 'Метры в шестой степени',
  DUT_SQUARE_FEET_PER_FOOT: 'Квадратные футы на фут',
  DUT_SQUARE_INCHES_PER_FOOT: 'Квадратные дюймы на фут',
  DUT_SQUARE_MILLIMETERS_PER_METER: 'Квадратные миллиметры на метр',
  DUT_SQUARE_CENTIMETERS_PER_METER: 'Квадратные сантиметры на метр',
  DUT_SQUARE_METERS_PER_METER: 'Квадратные метры на метр',
  DUT_KILOGRAMS_MASS_PER_METER: 'Килограммы на метр',
  DUT_POUNDS_MASS_PER_FOOT: 'Фунты на фут',
  DUT_RADIANS: 'Радианы',
  DUT_GRADS: 'Грады',
  DUT_RADIANS_PER_SECOND: 'Радианы в секунду',
  DUT_MILISECONDS: 'Миллисекунды',
  DUT_SECONDS: 'Секунды',
  DUT_MINUTES: 'Минуты',
  DUT_HOURS: 'Часы',
  DUT_KILOMETERS_PER_HOUR: 'Километры в час',
  DUT_MILES_PER_HOUR: 'Мили в час',
  DUT_KILOJOULES: 'Килоджоули',
  DUT_KILOGRAMS_MASS_PER_SQUARE_METER: 'Килограммы на квадратный метр',
  DUT_POUNDS_MASS_PER_SQUARE_FOOT: 'Фунты на квадратный фут',
  DUT_WATTS_PER_METER_KELVIN: 'Ватты на метр на Кельвин',
  DUT_JOULES_PER_GRAM_CELSIUS: 'Джоули на грамм на градусы Цельсия',
  DUT_JOULES_PER_GRAM: 'Джоулей на грамм',
  DUT_NANOGRAMS_PER_PASCAL_SECOND_SQUARE_METER:
    'Нанограммы на паскаль на секунды на кв. метры',
  DUT_OHM_METERS: 'Ом на метры',
  DUT_BRITISH_THERMAL_UNITS_PER_HOUR_FOOT_FAHRENHEIT:
    'БТЕ (британские тепловые единицы) в час на фут в градусах Фаренгейта',
  DUT_BRITISH_THERMAL_UNITS_PER_POUND_FAHRENHEIT:
    'БТЕ (британские тепловые единицы) на фунт в градусах Фаренгейта',
  DUT_BRITISH_THERMAL_UNITS_PER_POUND:
    'БТЕ (британские тепловые единицы) на фунт',
  DUT_GRAINS_PER_HOUR_SQUARE_FOOT_INCH_MERCURY:
    'Гран в час на квадратный фут на дюймы ртутного столба',
  DUT_PER_MILLE: 'Промилле',
  DUT_DECIMETERS: 'Дециметры',
  DUT_JOULES_PER_KILOGRAM_CELSIUS: 'Джоули на килограмм-градус Цельсия',
  DUT_MICROMETERS_PER_METER_CELSIUS: 'Микрометры на метр-градус Цельсия',
  DUT_MICROINCHES_PER_INCH_FAHRENHEIT: 'Микродюймы на метр-градус Фаренгейта',
  DUT_USTONNES_MASS: 'Американские тонны',
  DUT_USTONNES_FORCE: 'Американские тонны',
  DUT_LITERS_PER_MINUTE: 'Литры в минуту',
  DUT_FAHRENHEIT_DIFFERENCE: 'Градусы Фаренгейта',
  DUT_CELSIUS_DIFFERENCE: 'Градусы Цельсия',
  DUT_KELVIN_DIFFERENCE: 'Градусы Кельвина',
  DUT_RANKINE_DIFFERENCE: 'Градусы Ренкина',
}
