import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

import ru from 'vuetify/src/locale/ru.ts'

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00afa1',
        secondary: '#00afa1',
      },
      dark: {
        primary: '#00afa1',
        secondary: '#00afa1',
      },
    },
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
})
