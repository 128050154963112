import flatten from 'lodash/flatten'

export default {
  getParameterUnit: (state) => (key, value) =>
    state.revitParameters?.find((parameter) => parameter[key] === value)
      ?.displayUnit,
  checkParameterExistence: (state) => (key, value) =>
    state.revitParameters?.findIndex(
      (parameter) => parameter[key] === value
    ) !== -1,
  getCheckStatusById: (state) => (eirElementId) =>
    state.checkStatus[eirElementId] ?? null,
  getElementsById: (state) => (eirElementId) =>
    state.revitElements[eirElementId] ?? null,
  checkIfParamIsReadonly: (state) => (eirElementId, paramName) =>
    state.revitElements[eirElementId]?.[0]?.parameters?.find(
      ({ name }) => name === paramName
    )?.isReadOnly ?? false,
  checkIfParamIsMultipleFound: (state) => (eirElementId, paramName) => {
    const arrayOfParamArrays =
      state.revitElements[eirElementId]?.map(({ parameters }) => parameters) ??
      []

    return flatten(arrayOfParamArrays)?.some(
      ({ name, multipleFound }) => name === paramName && multipleFound
    )
  },
  checkIfElementIsFailed: (state) => (id) =>
    state.failedElementsIds.includes(id),
  getSelectedSaprElements: (state) => (eirElementId) => {
    const selectedEls = state.selectedSaprElements ?? []
    const allEls = state.revitElements[eirElementId] ?? []

    return selectedEls.length ? selectedEls : allEls
  },
  checkIfParamIsNotPresent: (state, getters) => (eirElementId, paramName) => {
    const selectedEls = getters.getSelectedSaprElements(eirElementId)

    return selectedEls.some((el) => checkIfNotPresent(el, paramName))
  },
  getParamCreatingStatus: (state) => (eirElementId, paramName) =>
    state.createStatus[`${eirElementId}_${paramName}`],
  checkIfSomeParamWasCreated: (state) => (eirElementId) =>
    Object.entries(state.createStatus).some(
      ([key, status]) => key.includes(eirElementId) && status === 3
    ),
  /*
   * test checks
   * */
  getTestCheckStatusById: (state) => (eirElementId) =>
    state.testCheckStatus[eirElementId] ?? null,
  getTestElementsById: (state) => (eirElementId) =>
    state.testRevitElements[eirElementId] ?? null,
}

function checkIfNotPresent(element, paramName) {
  if (Object.hasOwnProperty.call(element, 'link')) element = element.link

  return !element.parameters?.find(({ name }) => name === paramName)?.present
}
