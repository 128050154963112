export const logger = (type, ...args) => {
  const env = process.env.NODE_ENV || 'development'
  const canLog = env === 'development' || env === 'devlocal' || type === 'error'
  // eslint-disable-next-line no-console
  if (canLog && type && args?.length) console?.[type](...args)
}

export const loggerPlugin = (store) => {
  store.$logger = logger
}

export default {
  install(Vue) {
    Vue.prototype.$logger = logger
  },
}
