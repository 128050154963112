export const DEFAULT_QUERY_PARAMS = {
  limit: 1000,
  offset: 0,
  ordering: '-created_at',
}

export const DEFAULT_NOTIFY_SNACKBAR = {
  show: false,
  title: null,
  message: 'Успех!',
  options: {
    color: 'primary',
    right: true,
    timeout: 3000,
    multiLine: false,
    autoHeight: false,
  },
}

export const DEFAULT_EIR_INSTANCES_STATE = {
  eirInstances: [],
  eirInstancesTotal: 0,
  eirInstancesOptions: {},
  eirInstancesFilters: {},
}

export const DEFAULT_EIR_ELEMENTS_STATE = {
  totalEirElements: 0,
  eirElements: [],
  eirOptions: {},
  eirFilters: {},
  eirElementInfo: {},
}

export const DEFAULT_PARAMETERS_STATE = {
  totalParameters: 0,
  parameters: [],
  paramOptions: {},
  paramFilters: {},
  saprFields: [],
}
