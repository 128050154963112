export default {
  // TODO не нашёл "Логический" и "Действительное число" которые есть в ренге, те что закомментил в ренге тоже не нашёл
  // ParameterType_Undefined: 'Parameter type is undefined',
  ParameterType_Bool: 'Булевый',
  ParameterType_Int: 'Целое число',
  // ParameterType_Double: 'Parameter type is double.',
  ParameterType_String: 'Строка',
  ParameterType_Length: 'Длина',
  ParameterType_Angle: 'Угол',
  ParameterType_IntEnumeration: 'Перечисление',
  // ParameterType_IntID: 'Parameter type is interger id.',
  ParameterType_Area: 'Площадь',
  ParameterType_Volume: 'Объём',
  ParameterType_Mass: 'Масса',
  // ParameterType_Temperature: 'Parameter type is temperature measure.',
  // ParameterType_Density: 'Parameter type is density.',
  // ParameterType_ThermalConductivity: 'Parameter type is termal conductivity measure.',
  // ParameterType_Voltage: 'Parameter type is voltage measure.',
  // ParameterType_List: 'Parameter type is list.',
}
