const ignoredFieldsKeys = [
  'multipleFound',
  'isTypeParameter',
  'isReadOnly',
  'revitParameterGroup',
  'match',
]

const variantList = {
  sharedParameter: 0,
  systemParameter: 1,
  parameter: 2,
}
const keys = [
  ['revitGuid', 'revitType', 'revitDataType', 'revitIsShared', 'revitIsSystem'],
  ['revitIsShared', 'revitIsSystem'],
  ['revitDataType', 'revitIsShared', 'revitIsSystem'],
]

export default function checkParameter(parameter = {}, type = null) {
  return Object.keys(parameter).reduce((acc, key) => {
    const field = parameter[key]

    if (typeof field === 'object') {
      return acc && checkParameter(parameter.saprFieldsFullness, parameter.type)
    }

    if (
      typeof field === 'boolean' &&
      ignoredFieldsKeys.every((fieldKey) => !fieldKey.includes(key))
    ) {
      if (type) {
        const currentKeys = keys[variantList[type] ?? 2]
        const needToCheck = currentKeys.some((fieldKey) =>
          fieldKey.includes(key)
        )

        if (needToCheck) return acc && field
        else return acc
      }

      return acc && field
    }

    return acc
  }, true)
}
