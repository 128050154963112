import cloneDeep from 'lodash/cloneDeep'
import { DEFAULT_NOTIFY_SNACKBAR } from '@/store/constants'

const state = () => ({
  notifySnackbar: cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
  isDark: localStorage.getItem('irma-theme') === 'true',
})

const mutations = {
  triggerNotifySnackbar(state, options) {
    const notifySnackbar = cloneDeep(DEFAULT_NOTIFY_SNACKBAR)

    if (options)
      Object.keys(options).forEach(
        (key) => (notifySnackbar[key] = options[key])
      )

    state.notifySnackbar = notifySnackbar
  },
  triggerNotifySnackbarShow(state, bool) {
    state.notifySnackbar.show = bool
  },
  switchTheme(state) {
    state.isDark = !state.isDark
    localStorage.setItem('irma-theme', state.isDark)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
