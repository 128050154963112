import unionWith from 'lodash/unionWith'

/**
 * @description Объединяет массивы по ключу
 * @param arr1 {Array} Первый массив, элементы которого будут основой (если совпадут с элементами из второго)
 * @param arr2 {Array} Второй массив, с которым объединяем
 * @param key1 {String} Ключ 1, по которому сравниваются элементы (напр. id)
 * @param key2 {String} Ключ 2, по которому сравниваются элементы, чтобы наверняка (напр. name)
 * */
export default function (arr1, arr2, key1, key2 = null) {
  return unionWith(arr1, arr2, function (arrVal, othVal) {
    return (
      arrVal[key1] === othVal[key1] &&
      (key2 ? arrVal[key2] === othVal[key2] : true)
    )
  })
}
