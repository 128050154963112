<template>
  <v-dialog v-model="showModel" width="500" @keydown.esc="showModel = false">
    <v-card flat>
      <v-card-title class="pb-4">Обучение</v-card-title>

      <v-card-text>
        <ul style="list-style-type: none" class="pl-0">
          <li>
            Урок 1:
            <a href="https://youtu.be/1-Y0KijE-vw" target="_blank">
              Начало работы;
            </a>
          </li>
          <li>
            Урок 2:
            <a
              href="https://bim.vc/edu/courses/irma-servis-upravleniya-bim-razrabotkoy-onlayn/vvedenie-irma/sozdanie-trebovaniya/"
              target="_blank"
            >
              Создание требований;
            </a>
          </li>
          <li>
            Урок 3:
            <a
              href="https://bim.vc/edu/courses/irma-servis-upravleniya-bim-razrabotkoy-onlayn/vvedenie-irma/proverka-trebovaniy/"
              target="_blank"
            >
              Проверка требований и параметризация.
            </a>
          </li>
        </ul>
      </v-card-text>
    </v-card>

    <v-card flat>
      <v-card-title class="pb-0">Контакты</v-card-title>

      <v-list class="px-2">
        <v-list-item class="pt-0">
          <v-list-item-icon>
            <v-icon color="primary">mdi-email</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <a href="mailto:https://t.me/bimlib">info@irma.pro</a>
            </v-list-item-title>
            <v-list-item-subtitle>Почта</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset />

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-phone</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <a href="tel:+78005553410">8 (800) 555-34-10</a>
            </v-list-item-title>
            <v-list-item-subtitle>Телефон</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset />

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-message-text</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <a href="https://t.me/bimlib" target="_blank">
                https://t.me/bimlib
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>Телеграм-чат</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action />

          <v-list-item-content>
            <v-list-item-title>
              <a href="https://vk.com/bimlib" target="_blank">
                https://vk.com/bimlib
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>Написать в VK</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card flat>
      <v-card-title class="pb-4">Плагины</v-card-title>
      <v-card-subtitle class="caption pb-5">
        Поддерживаются версии Revit с 2019 по 2023
      </v-card-subtitle>

      <v-card-text>
        <v-list-item v-if="pluginVersion" class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <span class="font-weight-bold">Ваша версия плагина:&nbsp;</span>
              {{ pluginVersion }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div>
          <v-btn
            v-if="pluginLinkRevit"
            label
            color="primary"
            small
            target="_blank"
            :href="pluginLinkRevit"
            elevation="10"
          >
            <v-icon class="mr-2" size="20">mdi-cloud-download</v-icon>
            Скачать плагин для Revit
          </v-btn>
          <v-btn
            text
            small
            color="primary"
            target="_blank"
            :href="instructionLink"
          >
            <v-icon class="mr-1" size="20">mdi-information-outline</v-icon>
            Инструкция по установке
          </v-btn>
        </div>

        <div class="mt-3">
          <v-btn
            v-if="pluginLinkRenga"
            label
            color="primary"
            small
            target="_blank"
            :href="pluginLinkRenga"
            elevation="10"
          >
            <v-icon class="mr-2" size="20">mdi-cloud-download</v-icon>
            Скачать плагин для Renga
          </v-btn>
        </div>

        <div class="mt-3">
          <v-btn
            text
            small
            color="primary"
            class="px-0"
            target="_blank"
            :href="demoLink"
            @click.native="$logActionEvent('Скачан демо-проект для Revit')"
          >
            <v-icon class="mr-1" size="20">mdi-alpha-r-box-outline</v-icon>
            Демо-проект для Revit (.rvt, от 2020 и выше)
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TheHelpModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pluginLinkRevit: process.env.VUE_APP_REVIT_INSTALLER_LINK || null,
    pluginLinkRenga: process.env.VUE_APP_RENGA_INSTALLER_LINK || null,
    instructionLink: process.env.VUE_APP_REVIT_INSTRUCTION_LINK || null,
    demoLink: process.env.VUE_APP_REVIT_DEMO_LINK || null,
  }),
  computed: {
    ...mapState('revit', ['pluginVersion']),
    ...mapGetters(['isAdmin', 'isManager']),
    showModel: {
      get() {
        return this.value
      },
      set() {
        this.$emit('input')
      },
    },
  },
}
</script>
