import Vue from 'vue'
import Vuex from 'vuex'

import { axiosPlugin } from '@/utils/axiosInstance'
import { logEventVuexPlugin } from '@/utils/logEvents'
import { loggerPlugin } from '@/utils/logger'

import projects from '@/store/projects'
import eirInstances from '@/store/eirInstances'
import users from '@/store/users'
import eirSets from '@/store/eirSets'
import eirElements from '@/store/eirElements'
import parameters from '@/store/parameters'
import filters from '@/store/filters'
import sections from '@/store/sections'
import ui from '@/store/ui'
import revit from '@/store/revit'
import versions from '@/store/versions'
import tags from '@/store/tags'
import catalogs from '@/store/catalogs'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [axiosPlugin, logEventVuexPlugin, loggerPlugin],
  modules: {
    projects,
    eirInstances,
    users,
    eirSets,
    eirElements,
    parameters,
    filters,
    ui,
    sections,
    revit,
    versions,
    tags,
    catalogs,
  },
  state: () => ({
    isAuth: null,
    userInfo: null,
    authToken: localStorage.getItem('kc_token'),
    error: false,
    errorText: '',
    keycloak: null,
    fromPlugin: localStorage.getItem('from_plugin'),
  }),
  mutations: {
    setAuthToken(state, payload) {
      state.authToken = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = { ...payload }
    },
    setAuth(state, payload) {
      state.isAuth = payload
    },
    showError(state, payload) {
      state.error = true
      state.errorText = payload
    },
    hideError(state) {
      state.error = false
    },
    setKeycloak(state, payload) {
      state.keycloak = payload
    },
  },
  getters: {
    isAdmin({ userInfo }) {
      // TODO: VUE_APP_KEYCLOAK_CLIENT_ID - убрать во всем приложении
      return (userInfo?.role ?? []).includes('admin')
    },
    isDesigner({ userInfo }) {
      const roles = userInfo?.role ?? []
      // TODO `customer` - это бим-менеджер, возможно ключ поменяется
      return !roles.includes('customer') && !roles.includes('admin')
    },
    isManager: ({ userInfo }) => (userInfo?.role ?? []).includes('customer'),
    getEmail: ({ userInfo }) => userInfo?.email ?? 'Пользователь',
  },
  actions: {
    keycloakLogout({ state }) {
      localStorage.removeItem('kc_token')
      localStorage.removeItem('kc_refreshToken')
      state.keycloak.logout()
    },
  },
})

export default store
export const useStore = () => store
