// Тип данных (в Ревите - тип параметра)
export default {
  Text: 'Текст',
  Integer: 'Целое',
  Number: 'Число',
  Length: 'Длина',
  Area: 'Площадь',
  Volume: 'Объем',
  Angle: 'Угол',
  URL: 'URL',
  Material: 'Материал',
  YesNo: 'Да/Нет',
  Force: 'Усилие',
  LinearForce: 'Распределенная нагрузка по линии',
  AreaForce: 'Распределенная  нагрузка',
  Moment: 'Момент',
  NumberOfPoles: 'Количество полюсов',
  FixtureUnit: 'Расход приборов',
  LoadClassification: 'Классификация нагрузок',
  Image: 'Изображение',
  MultilineText: 'Многострочный текст',
  HVACDensity: 'Плотность',
  HVACEnergy: 'Энергия',
  HVACFriction: 'Трение',
  HVACPower: 'Мощность',
  HVACPowerDensity: 'Удельная мощность',
  HVACPressure: 'Давление',
  HVACTemperature: 'Температура',
  HVACVelocity: 'Скорость',
  HVACAirflow: 'Воздушный поток',
  HVACDuctSize: 'Размер воздуховода',
  HVACCrossSection: 'Поперечный разрез',
  HVACHeatGain: 'Теплоприток',
  ElectricalCurrent: 'Ток',
  ElectricalPotential: 'Электрический потенциал',
  ElectricalFrequency: 'Частота',
  ElectricalIlluminance: 'Освещенность',
  ElectricalLuminousFlux: 'Световой поток',
  ElectricalPower: 'Мощность',
  HVACRoughness: 'Шероховатость',
  ElectricalApparentPower: 'Полная установленная мощность',
  ElectricalPowerDensity: 'Удельная мощность',
  PipingDensity: 'Плотность',
  PipingFlow: 'Расход',
  PipingFriction: 'Трение',
  PipingPressure: 'Давление',
  PipingTemperature: 'Температура',
  PipingVelocity: 'Скорость',
  PipingViscosity: 'Динамическая вязкость',
  PipeSize: 'Размер трубы',
  PipingRoughness: 'Шероховатость',
  Stress: 'Напряжение',
  UnitWeight: 'Удельный вес',
  ThermalExpansion: 'Коэффициент теплового расширения',
  LinearMoment: 'Линейный момент',
  ForcePerLength: 'Сосредоточенный коэффициент упругости',
  ForceLengthPerAngle: 'Сосредоточенный угловой коэффициент упругости',
  LinearForcePerLength: 'Линейный коэффициент упругости',
  LinearForceLengthPerAngle: 'Линейный угловой коэффициент упругости',
  AreaForcePerLength: 'Коэффициент упругости среды',
  PipingVolume: 'Объем',
  HVACViscosity: 'Динамическая вязкость',
  HVACCoefficientOfHeatTransfer: 'Коэффициент теплопередачи',
  HVACAirflowDensity: 'Плотность воздушного потока',
  Slope: 'Уклон',
  HVACCoolingLoad: 'Холодильная нагрузка',
  HVACCoolingLoadDividedByArea: 'Холодильная нагрузка на единицу площади',
  HVACCoolingLoadDividedByVolume: 'Холодильная нагрузка на единицу объема',
  HVACHeatingLoad: 'Отопительная нагрузка',
  HVACHeatingLoadDividedByArea: 'Отопительная нагрузка на единицу площади',
  HVACHeatingLoadDividedByVolume: 'Отопительная нагрузка на единицу объема',
  HVACAirflowDividedByVolume: 'Воздушный поток на единицу объема',
  HVACAirflowDividedByCoolingLoad:
    'Воздушный поток, отнесенный к холодильной нагрузке',
  HVACAreaDividedByCoolingLoad: 'Площадь, отнесенная к холодильной нагрузке',
  WireSize: 'Диаметр провода',
  HVACSlope: 'Уклон',
  PipingSlope: 'Уклон',
  Currency: 'Денежная единица',
  ElectricalEfficacy: 'Эффективность',
  ElectricalWattage: 'Мощность',
  ColorTemperature: 'Цветовая температура',
  ElectricalLuminousIntensity: 'Сила света',
  ElectricalLuminance: 'Яркость',
  HVACAreaDividedByHeatingLoad: 'Площадь на единицу отопительной нагрузки',
  HVACFactor: 'Коэффициент',
  ElectricalTemperature: 'Температура',
  ElectricalCableTraySize: 'Размер кабельного лотка',
  ElectricalConduitSize: 'Размер короба',
  ReinforcementVolume: 'Объем арматуры',
  ReinforcementLength: 'Длина армирования',
  ElectricalDemandFactor: 'Коэффициент спроса нагрузки',
  HVACDuctInsulationThickness: 'Толщина изоляции воздуховода',
  HVACDuctLiningThickness: 'Толщина внутренней изоляции воздуховода',
  PipeInsulationThickness: 'Толщина изоляции трубы',
  HVACThermalResistance: 'Термостойкость',
  HVACThermalMass: 'Тепловая нагрузка',
  Acceleration: 'Ускорение',
  BarDiameter: 'Диаметр стержня',
  CrackWidth: 'Ширина трещины',
  DisplacementDeflection: 'Смещение/прогиб',
  Energy: 'Энергия',
  StructuralFrequency: 'Частота',
  Mass: 'Масса',
  MassPerUnitLength: 'Масса на единицу длины',
  MomentOfInertia: 'Момент инерции',
  SurfaceArea: 'Площадь поверхности на единицу длины',
  Period: 'Период',
  Pulsation: 'Пульсация',
  ReinforcementArea: 'Армирование по площади',
  ReinforcementAreaPerUnitLength: 'Армирование по площади на единицу длины',
  ReinforcementCover: 'Защитный слой армирования',
  ReinforcementSpacing: 'Интервал армирования',
  Rotation: 'Вращение',
  SectionArea: 'Площадь сечения',
  SectionDimension: 'Размеры сечения',
  SectionModulus: 'Момент сопротивления сечения',
  SectionProperty: 'Свойство сечения',
  StructuralVelocity: 'Скорость',
  WarpingConstant: 'Постоянная перекоса',
  Weight: 'Вес',
  WeightPerUnitLength: 'Вес на единицу длины',
  HVACThermalConductivity: 'Теплопроводность',
  HVACSpecificHeat: 'Удельная теплоемкость',
  HVACSpecificHeatOfVaporization: 'Удельная теплоемкость парообразования',
  HVACPermeability: 'Проницаемость',
  ElectricalResistivity: 'Электрическое удельное сопротивление',
  MassDensity: 'Массовая плотность',
  MassPerUnitArea: 'Масса на единицу площади',
  PipeDimension: 'Размер трубы',
  PipeMass: 'Масса',
  PipeMassPerUnitLength: 'Масса на единицу длины',
  HVACTemperatureDifference: 'Разность температур',
  PipingTemperatureDifference: 'Разность температур',
  ElectricalTemperatureDifference: 'Разность температур',
}
