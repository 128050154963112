import ParameterType from '@/constants/revit/ParameterType'
import ParameterGroup from '@/constants/revit/ParameterGroup'
import DisplayUnitType from '@/constants/revit/DisplayUnitType'
import BuiltInCategoryEnumeration from '@/constants/revit/BuiltInCategoryEnumeration'
import BuiltInParameterEnumeration from '@/constants/revit/BuiltInParameterEnumeration'
import RengaParameterType from '@/constants/renga/ParameterType'
import RengaBuiltInCategoryEnumeration from '@/constants/renga/BuiltInCategoryEnumeration'

export * from './filters'
export * from './saprFieldsLabels'
export * from './paramType'
export {
  ParameterType,
  ParameterGroup,
  DisplayUnitType,
  BuiltInCategoryEnumeration,
  BuiltInParameterEnumeration,
  RengaParameterType,
  RengaBuiltInCategoryEnumeration,
}

export const SAPR_SYSTEMS = {
  revit: { name: 'Revit', code: 1 },
  renga: { name: 'Renga', code: 2 },
}
