import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './utils/vuetify'

import truncate from 'lodash/truncate'
import formatDate from './utils/dateFormat'

import globalEvents from './utils/globalEvents'
import logEvents from './utils/logEvents'
import { $api, $revit } from './utils/axiosInstance'
import logger from './utils/logger'

Vue.config.productionTip = false

Vue.prototype.$api = $api // axios instance
Vue.prototype.$revit = $revit

Vue.use(logEvents, store) // $logActionEvent
Vue.use(globalEvents) // TODO: глобальная шина событий не нужна, если есть vuex!
Vue.use(logger) // console logger

Vue.filter('formatDate', (value, mask) => formatDate(value, mask))
Vue.filter('truncate', (value, length, suffix = '...') =>
  truncate(value, { length, omission: suffix })
)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
