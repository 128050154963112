<template lang="html">
  <v-snackbar v-model="show" top v-bind="options">
    {{ message }}
    <template #action="{ attrs }">
      <v-btn v-bind="attrs" dark text @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('ui', ['notifySnackbar']),
    show: {
      get() {
        return this.notifySnackbar.show
      },
      set(value) {
        if (!value) this.close()
      },
    },
    title: (vm) => vm.notifySnackbar.title,
    message: (vm) => vm.notifySnackbar.message,
    options: (vm) => vm.notifySnackbar.options || {},
  },
  methods: {
    ...mapMutations('ui', [
      'triggerNotifySnackbar',
      'triggerNotifySnackbarShow',
    ]),
    close() {
      this.triggerNotifySnackbarShow(false)
      setTimeout(() => this.triggerNotifySnackbar(), 100)
    },
  },
}
</script>
