/**
 * API: https://irma.bimlib.ru/redoc/#tag/EIR_ELEMENTS
 * */

import cloneDeep from 'lodash/cloneDeep'
import router from '@/router'
import { vTableOptionsPrepare } from '@/utils/storeHelpers'
import {
  DEFAULT_EIR_ELEMENTS_STATE,
  DEFAULT_NOTIFY_SNACKBAR,
  DEFAULT_QUERY_PARAMS,
} from '@/store/constants'

const endpoint = 'v1/eir_elements'

export default {
  namespaced: true,
  state: () => cloneDeep(DEFAULT_EIR_ELEMENTS_STATE),
  getters: {
    getCurrentEirElementFilterId: (state) =>
      state.eirElementInfo?.revit_filter?.id ?? null,
  },
  mutations: {
    setEirElements(state, payload) {
      state.eirElements = payload
    },
    setEirElementInfo(state, payload) {
      state.eirElementInfo = payload
    },
    setTotalEirElements(state, number) {
      state.totalEirElements = number
    },
    setEirOptions(state, object) {
      state.eirOptions = object
    },
    setEirFilters(state, object) {
      state.eirFilters = object
    },
    resetElementsState(state) {
      const defaultState = cloneDeep(DEFAULT_EIR_ELEMENTS_STATE)

      Object.keys(defaultState).map((key) => (state[key] = defaultState[key]))
    },
  },
  actions: {
    /**
     * @description Получение списка всех требований, доступных организации
     * @param _context
     * @param options {object|null} Объект опций для таблиц vuetify
     * @param filters {object|null} Объект параметров запроса
     * */
    async fetchEirElements(
      { commit, state },
      { options = null, filters = null } = {}
    ) {
      if (options) commit('setEirOptions', vTableOptionsPrepare(options))
      if (filters) commit('setEirFilters', filters)

      let params = {
        ...DEFAULT_QUERY_PARAMS,
        ordering: '-id',
        ...state.eirFilters,
        ...state.eirOptions,
      }

      try {
        const { data } = await this.$api
          .setTrace('eirElements/fetchEirElements')
          .get(endpoint, { params })
        commit('setEirElements', data?.results ?? data ?? [])
        commit('setTotalEirElements', data?.count ?? data.length ?? 0)
      } catch (e) {
        this.$logger('error', '[eirElements/fetchEirElements]', e)
      }
    },
    /**
     * @description Получение информации о конкретном требовании
     * */
    async fetchEir({ commit }, id) {
      try {
        const { data } = await this.$api
          .setTrace('eirElements/fetchEir')
          .get(`${endpoint}/${id}`)
        commit('setEirElementInfo', data)
      } catch (error) {
        /**
         * Если требование не найдено, то показываем страницу с 404 ошибкой
         * */
        if (error.response.status === 404) {
          await router.replace({ name: '404' })
        }
      }
    },
    /**
     * @description Создание нового требования к элементам
     * @param _context
     * @param eir_set {string} ID набора требований
     * @param name {string} наименование требования
     * @param description {string} описание требования
     * */
    async createEirElement(_context, { eir_set, name = '', description = '' }) {
      try {
        const {
          data: { id },
        } = await this.$api.setTrace('eirElements/create').post(endpoint, {
          eir_set,
          name,
          description,
        })

        return id
      } catch (e) {
        this.$logger('error', '[eirElements/create]', e)
      }
    },
    /**
     * @description Частичное обновление информации о требовании
     * @param _context
     * @param id {string} ID требования
     * @param eir_set {string} ID набора требований
     * @param name {string} наименование требования
     * @param description {string} описание требования
     * */
    async updateEirElement(
      _context,
      { id, eir_set, name = '', description = '' }
    ) {
      await this.$api
        .setTrace('eirElements/update')
        .patch(`${endpoint}/${id}`, {
          eir_set,
          name,
          description,
        })
    },
    /**
     * @description Удаление требования
     * @param _context
     * @param id {string} ID требования
     * */
    async deleteEirElement(_context, id) {
      await this.$api.setTrace('eirElements/delete').delete(`${endpoint}/${id}`)
    },
    /**
     * @description Добавление параметров к требованию
     * */
    async bindParametersToEirElement({ commit }, { eirId, paramsIds }) {
      if (!eirId || !paramsIds?.length) return

      try {
        await this.$api
          .setTrace('eirElements/post')
          .post(`${endpoint}/${eirId}/add_parameters`, paramsIds)
      } catch (e) {
        const options = {
          ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
          message: 'Привязать параметры не удалось!',
          show: true,
        }
        options.options.color = 'error'
        commit('ui/triggerNotifySnackbar', options, { root: true })

        this.$logger('error', '[eirElements/addParameters]', e)
      }
    },
    /**
     * @description Удаление параметров из требования
     * */
    async removeParametersEirElement({ commit }, { eirId, paramsIds }) {
      if (!eirId || !paramsIds?.length) return

      try {
        await this.$api
          .setTrace('eirElements/removeParameters')
          .post(`${endpoint}/${eirId}/del_parameters`, paramsIds)
      } catch (e) {
        const options = {
          ...cloneDeep(DEFAULT_NOTIFY_SNACKBAR),
          message: 'Отвязать параметр не удалось!',
          show: true,
        }
        options.options.color = 'error'
        commit('ui/triggerNotifySnackbar', options, { root: true })

        this.$logger('error', '[eirElements/removeParameters]', e)
      }
    },
  },
}
