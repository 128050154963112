import { camelCase, isPlainObject, mapKeys } from 'lodash'

export function keysToCamelCaseDeep(objectOrArray) {
  if (Array.isArray(objectOrArray))
    return objectOrArray.map(keysToCamelCaseDeep)

  return mapKeys(objectOrArray, (value, key) => {
    if (isPlainObject(value)) objectOrArray[key] = keysToCamelCaseDeep(value)

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (isPlainObject(item))
          objectOrArray[key][index] = keysToCamelCaseDeep(item)
      })
    }

    return camelCase(key)
  })
}

export function renameKeysBackDeep(targetObject, originalModel) {
  const originalKeys = Object.keys(originalModel)

  return mapKeys(targetObject, (value, key) => {
    const originalKey = originalKeys.find((k) => camelCase(k) === key)

    if (isPlainObject(value))
      targetObject[key] = renameKeysBackDeep(value, originalModel[originalKey])

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (isPlainObject(item))
          targetObject[key][index] = renameKeysBackDeep(item)
      })
    }

    return originalKey
  })
}
