// Группа
export default {
  PG_GEO_LOCATION: 'Геопозиционирование',
  PG_STRUCTURAL_SECTION_GEOMETRY: 'Геометрия сечения несущей конструкции',
  PG_ENERGY_ANALYSIS_BLDG_CONS_MTL_THERMAL_PROPS: 'Тепловые свойства материала',
  PG_ENERGY_ANALYSIS_ROOM_SPACE_DATA: 'Данные о помещении/пространстве',
  PG_ENERGY_ANALYSIS_BUILDING_DATA: 'Данные о здании',
  PG_COUPLER_ARRAY: 'Набор',
  PG_ENERGY_ANALYSIS_ADVANCED: 'Расширенная',
  PG_RELEASES_MEMBER_FORCES: 'Снятие связей/усилия для элемента',
  PG_SECONDARY_END: 'Второстепенный конец',
  PG_PRIMARY_END: 'Основной конец',
  PG_MOMENTS: 'Моменты',
  PG_FORCES: 'Силы',
  PG_FABRICATION_PRODUCT_DATA: 'Данные о продукте производителя',
  PG_REFERENCE: 'Ссылка',
  PG_GEOMETRY_POSITIONING: 'Геометрическое положение',
  PG_DIVISION_GEOMETRY: 'Геометрия разделения',
  PG_SEGMENTS_FITTINGS: 'Сегменты и соединительные детали',
  PG_CONTINUOUSRAIL_END_TOP_EXTENSION: 'Примыкание (конец/верх)',
  PG_CONTINUOUSRAIL_BEGIN_BOTTOM_EXTENSION: 'Примыкание (начало/низ)',
  PG_STAIRS_WINDERS: 'Забежные ступени',
  PG_STAIRS_SUPPORTS: 'Опоры',
  PG_STAIRS_OPEN_END_CONNECTION: 'Соединение в конце',
  PG_RAILING_SYSTEM_SECONDARY_FAMILY_HANDRAILS: 'Перила 2',
  PG_TERMINTATION: 'Ограничения',
  PG_STAIRS_TREADS_RISERS: 'Проступи/подступенки',
  PG_STAIRS_CALCULATOR_RULES: 'Правила расчета',
  PG_SPLIT_PROFILE_DIMENSIONS:
    'Размеры     (линейные единицы или % от толщины)',
  PG_LENGTH: 'Длина',
  PG_NODES: 'Узлы',
  PG_ANALYTICAL_PROPERTIES: 'Свойства аналитической модели',
  PG_ANALYTICAL_ALIGNMENT: 'Выравнивание аналитической модели',
  PG_SYSTEMTYPE_RISEDROP: 'Подъем/опуск',
  PG_LINING: 'Внутренняя изоляция',
  PG_INSULATION: 'Изоляционный слой',
  PG_OVERALL_LEGEND: 'Общая легенда',
  PG_VISIBILITY: 'Видимость',
  PG_SUPPORT: 'Опоры',
  PG_RAILING_SYSTEM_SEGMENT_V_GRID: 'Линии сетки V',
  PG_RAILING_SYSTEM_SEGMENT_U_GRID: 'Линии сетки U',
  PG_RAILING_SYSTEM_SEGMENT_POSTS: 'Стойки',
  PG_RAILING_SYSTEM_SEGMENT_PATTERN_REMAINDER: 'Остаток образца',
  PG_RAILING_SYSTEM_SEGMENT_PATTERN_REPEAT: 'Повтор образца',
  PG_RAILING_SYSTEM_FAMILY_SEGMENT_PATTERN: 'Образец сегмента (по умолчанию)',
  PG_RAILING_SYSTEM_FAMILY_HANDRAILS: 'Перила 1',
  PG_RAILING_SYSTEM_FAMILY_TOP_RAIL: 'Верхний поручень',
  PG_CONCEPTUAL_ENERGY_DATA_BUILDING_SERVICES:
    'Модель энергопотребления - Инженерные сети здания',
  PG_DATA: 'Данные',
  PG_ELECTRICAL_CIRCUITING: 'Электросети - Создание цепей',
  PG_GENERAL: 'Общие',
  PG_FLEXIBLE: 'Адаптивный компонент',
  PG_ENERGY_ANALYSIS_CONCEPTUAL_MODEL: 'Аналитическая модель энергопотребления',
  PG_ENERGY_ANALYSIS_DETAILED_MODEL: 'Подробная модель',
  PG_ENERGY_ANALYSIS_DETAILED_AND_CONCEPTUAL_MODELS: 'Основная',
  PG_FITTING: 'Соединительные детали',
  PG_CONCEPTUAL_ENERGY_DATA: 'Концептуальное энергопотребление',
  PG_AREA: 'Площадь',
  PG_ADSK_MODEL_PROPERTIES: 'Свойства модели',
  PG_CURTAIN_GRID_V: 'Линии сетки V',
  PG_CURTAIN_GRID_U: 'Линии сетки U',
  PG_DISPLAY: 'Представление',
  PG_ANALYSIS_RESULTS: 'Результаты анализа',
  PG_SLAB_SHAPE_EDIT: 'Редактирование формы перекрытия',
  PG_LIGHT_PHOTOMETRICS: 'Фотометрические',
  PG_PATTERN_APPLICATION: 'Применение образца',
  PG_GREEN_BUILDING: 'Свойства экологически чистого здания',
  PG_PROFILE_2: 'Профиль 2',
  PG_PROFILE_1: 'Профиль 1',
  PG_PROFILE: 'Профиль',
  PG_TRUSS_FAMILY_BOTTOM_CHORD: 'Нижние пояса',
  PG_TRUSS_FAMILY_TOP_CHORD: 'Верхние пояса',
  PG_TRUSS_FAMILY_DIAG_WEB: 'Раскосные решетки',
  PG_TRUSS_FAMILY_VERT_WEB: 'Стойки',
  PG_TITLE: 'Шрифт заголовков',
  PG_FIRE_PROTECTION: 'Система пожаротушения',
  PG_ROTATION_ABOUT: 'Поворот вокруг оси',
  PG_TRANSLATION_IN: 'Перемещение по направлению оси',
  PG_ANALYTICAL_MODEL: 'Аналитическая модель',
  PG_REBAR_ARRAY: 'Набор арматурных стержней',
  PG_REBAR_SYSTEM_LAYERS: 'Слои',
  PG_CURTAIN_GRID: 'Сетка',
  PG_CURTAIN_MULLION_2: 'Импосты сетки 2',
  PG_CURTAIN_MULLION_HORIZ: 'Горизонтальные импосты',
  PG_CURTAIN_MULLION_1: 'Импосты сетки 1',
  PG_CURTAIN_MULLION_VERT: 'Вертикальные импосты',
  PG_CURTAIN_GRID_2: 'Сетка 2',
  PG_CURTAIN_GRID_HORIZ: 'Горизонтальная сетка',
  PG_CURTAIN_GRID_1: 'Сетка 1',
  PG_CURTAIN_GRID_VERT: 'Вертикальная сетка',
  PG_IFC: 'Параметры IFC',
  PG_AELECTRICAL: 'Электросети',
  PG_ENERGY_ANALYSIS: 'Расчет энергопотребления',
  PG_STRUCTURAL_ANALYSIS: 'Расчет несущих конструкций',
  PG_MECHANICAL_AIRFLOW: 'Механизмы - Расход',
  PG_MECHANICAL_LOADS: 'Механизмы - Нагрузки',
  PG_ELECTRICAL_LOADS: 'Электросети - Нагрузки',
  PG_ELECTRICAL_LIGHTING: 'Электросети - Освещение',
  PG_TEXT: 'Текст',
  PG_VIEW_CAMERA: 'Камера',
  PG_VIEW_EXTENTS: 'Границы',
  PG_PATTERN: 'Образец',
  PG_CONSTRAINTS: 'Зависимости',
  PG_PHASING: 'Стадии',
  PG_MECHANICAL: 'Механизмы',
  PG_STRUCTURAL: 'Несущие конструкции',
  PG_PLUMBING: 'Сантехника',
  PG_ELECTRICAL: 'Электросети',
  PG_STAIR_STRINGERS: 'Косоуры/Тетивы',
  PG_STAIR_RISERS: 'Подступенки',
  PG_STAIR_TREADS: 'Проступи',
  PG_UNDERLAY: 'Подложка',
  PG_MATERIALS: 'Материалы и отделка',
  PG_GRAPHICS: 'Графика',
  PG_CONSTRUCTION: 'Строительство',
  PG_GEOMETRY: 'Размеры',
  PG_IDENTITY_DATA: 'Идентификация',
  INVALID: 'Прочее',
}
