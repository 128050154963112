/**
 * API: http://prod.bim.bimlib.ru:8802/docs#/
 * */

const state = () => ({
  currentCatalog: {},
  categoryList: [],
  revitCategoryList: [],
  catalogList: [],
  productList: [],
  paramList: [],
  manufacturerList: [],
})

const getters = {}

const mutations = {
  setCurrentCatalog(state, payload) {
    state.currentCatalog = payload
  },
  setCategoryList(state, array) {
    state.categoryList = array
  },
  setRevitCategoryList(state, array) {
    state.revitCategoryList = array
  },
  setCatalogList(state, array) {
    state.catalogList = array
  },
  setProductList(state, array) {
    state.productList = array
  },
  appEndProductList(state, array) {
    state.productList.push(...array)
  },
  setParamList(state, array) {
    state.paramList = array
  },
  setManufacturerList(state, array) {
    state.manufacturerList = array
  },
  reset(state) {
    state.productList = []
  },
}

const actions = {
  /**
   * Отправка email
   * */
  async sendEmail(_, payload) {
    let error = false
    try {
      await this.$catalogs.post('/send_email', payload)
    } catch (e) {
      error = true
      console.error('[sendEmail]', e)
    }
    return error
  },
  /**
   * @description Удаление каталога
   * */
  async removeCatalogById(_, id) {
    let error = false
    try {
      await this.$catalogs.get('/delete_catalog', {
        params: { catalog_id: id },
      })
    } catch (e) {
      error = true
      console.error('[removeCatalogById]', e)
    }
    return error
  },
  /**
   * @description Загрузка изображений
   * */
  uploadImage(_, formData) {
    return this.$catalogs.post('/upload_image', formData)
  },
  /**
   * @description Сохранение каталога
   * */
  async saveCatalog(_, payload) {
    let error = false
    try {
      const { data } = await this.$catalogs.post('/save_catalog', payload)
      if (data.status !== 'done') error = true
    } catch (e) {
      error = true
      console.error('[saveCatalog]', e)
    }
    return error
  },
  /**
   * @description Получение каталога по id
   * */
  async fetchCatalogById({ commit }, item_id) {
    try {
      const { data } = await this.$catalogs.get('/get_catalog_by_id', {
        params: { item_id },
      })
      commit('setCurrentCatalog', data)
    } catch (e) {
      console.error('[fetchCatalogItemById]', e)
    }
  },
  /**
   * @description Парсер каталогов из excel
   * */
  async importExcelParse(_, formData) {
    let error = false
    try {
      await this.$catalogs.post('/excel_parse', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { save: true },
      })
    } catch (e) {
      error = true
      console.error('[importExcelParse]', e)
    }
    return error
  },
  /**
   * @description Получение категорий
   * */
  async fetchCategoryList({ commit }) {
    try {
      const { data } = await this.$catalogs.get('/categories')
      commit('setCategoryList', data)
    } catch (e) {
      console.error('[fetchCategoryList]', e)
    }
  },
  /**
   * @description Получение категорий Revit
   * */
  async fetchRevitCategoryList({ commit }) {
    try {
      const { data } = await this.$catalogs.get('/revit_categories')
      commit('setRevitCategoryList', data)
    } catch (e) {
      console.error('[fetchRevitCategoryList]', e)
    }
  },
  /**
   * @description Получение всех каталогов
   * */
  async fetchCatalogList({ commit }) {
    try {
      const { data } = await this.$catalogs.get('/get_catalog_all')
      commit('setCatalogList', data)
    } catch (e) {
      console.error('[fetchCatalogList]', e)
    }
  },
  /**
   * @description Получение списка продуктов
   * */
  async fetchProductList({ commit }, payload) {
    try {
      const { data } = await this.$catalogs.post('/products', payload)
      commit('setProductList', data)
      return data
    } catch (e) {
      console.error('[fetchProductList]', e)
    }
  },
  /**
   * @description Получить и добавить в конец списка
   * */
  async appEndNewProductList({ commit }, payload) {
    try {
      const { data } = await this.$catalogs.post('/products', payload)
      commit('appEndProductList', data)
      return data
    } catch (e) {
      console.error('[appEndNewProductList]', e)
      return null
    }
  },
  /**
   * @description Получение всех параметров
   * */
  async fetchParamList({ commit }) {
    try {
      const { data } = await this.$catalogs.get('/params')
      commit('setParamList', data)
    } catch (e) {
      console.error('[fetchParamList]', e)
    }
  },
  /**
   * @description Получение всех производителей
   * */
  async fetchManufacturerList({ commit }) {
    try {
      const { data } = await this.$catalogs.get('/manufacturers')
      commit('setManufacturerList', data)
    } catch (e) {
      console.error('[fetchManufacturerList]', e)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
