/**
 * API: https://irma.bimlib.ru/redoc/#tag/PARAMETER_REQUIREMENTS
 * */

import {
  DEFAULT_PARAMETERS_STATE,
  DEFAULT_QUERY_PARAMS,
} from '@/store/constants'
import { vTableOptionsPrepare } from '@/utils/storeHelpers'
import cloneDeep from 'lodash/cloneDeep'

const endpoint = 'v1/parameters'
const saprEndpoint = 'v1/sapr_parameters_fields'

export default {
  namespaced: true,
  state: () => cloneDeep(DEFAULT_PARAMETERS_STATE),
  mutations: {
    setParameters(state, payload) {
      state.parameters = payload
    },
    setTotalParameters(state, number) {
      state.totalParameters = number
    },
    setParamOptions(state, object) {
      state.paramOptions = object
    },
    setParamFilters(state, object) {
      state.paramFilters = object
    },
    resetParametersState(state) {
      const defaultState = cloneDeep(DEFAULT_PARAMETERS_STATE)

      Object.keys(defaultState).map((key) => (state[key] = defaultState[key]))
    },
    setSaprFields(state, fields) {
      state.saprFields = fields
    },
  },
  getters: {
    maxSortingPriority: ({ parameters = [] }) =>
      parameters.reduce((a, b) => {
        a = a?.sorting_priority ?? a
        b = b?.sorting_priority ?? b

        return a > b ? a : b
      }, 0),
  },
  actions: {
    /**
     * @description Получение списка всех параметров, доступных организации
     * @param _context
     * @param options {object|null} Объект опций для таблиц vuetify
     * @param filters {object|null} Объект параметров запроса
     * @param onlyValue {boolean} Для разового запроса без обновления стора
     * */
    async fetchParams(
      { commit },
      { options = null, filters = null, onlyValue = false } = {}
    ) {
      if (!onlyValue) {
        options = options ? vTableOptionsPrepare(options) : null

        if (options) commit('setParamOptions', options)
        if (filters) commit('setParamFilters', filters)
      }

      let params = {
        ...DEFAULT_QUERY_PARAMS,
        ...(filters ?? {}),
        ...(options ?? {}),
        ordering: '-sorting_priority',
      }

      try {
        const { data } = await this.$api
          .setTrace('parameters/fetchParams')
          .get(endpoint, {
            params,
          })

        if (!onlyValue) {
          commit('setParameters', data?.results ?? data ?? [])
          commit('setTotalParameters', data?.count ?? data.length ?? 0)
        } else {
          return data?.results ?? data ?? []
        }
      } catch (e) {
        this.$logger('error', '[parameters/fetchParams]', e)
      }
    },
    /**
     * @description Создание нового параметра
     * */
    async createParameter(_, object) {
      await this.$api.post(endpoint, object)
    },
    /**
     * @description Частичное обновление информации об параметре
     * */
    async updateParameter(_, object) {
      await this.$api.patch(`${endpoint}/${object.id}`, object)
    },
    /**
     * @description Удаление параметра
     * */
    async deleteParameter(_, id) {
      await this.$api.delete(`${endpoint}/${id}`)
    },
    /**
     * @description Множественное изменение параметров
     * */
    async changeParameters(_, array = []) {
      await this.$api.patch(`${endpoint}/change`, array)
    },
    /**
     * @description Получение специфичных полей САПРа
     * */
    async fetchSaprFields({ commit }) {
      try {
        const { data } = await this.$api
          .setTrace('parameters/fetchSaprFields')
          .get(saprEndpoint)
        commit('setSaprFields', data)
      } catch (e) {
        this.$logger('error', '[parameters/fetchSaprFields]', e)
      }
    },
    /**
     * @description Редактирование специфичных полей САПРа
     * */
    async editSaprFields(_context, { paramId = null, fields }) {
      if (paramId === null) return

      try {
        await this.$api
          .setTrace('parameters/editSaprFields')
          .post(`${endpoint}/${paramId}/edit_sapr_fields`, fields)
      } catch (e) {
        this.$logger('error', '[parameters/editSaprFields]', e)
      }
    },
  },
}
